import React from "react";
import AFPLogo from '../../logo_afp.png';
import _Login from './login'
import Recaptcha from 'react-google-invisible-recaptcha';
import ModalInformativo from '../../componentes/modal'
import LoginEjecutivo from '../ejecutivo/index'
import EliminarSolicitud from '../eliminar_solicitud/index'
import IconEjecutivo from './user.png'
import Footer from "../../componentes/footer";
import BlockUi from 'react-block-ui';
const { format } = require('rut.js')

class Home extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showModalInformativo: false,
      textModalInformativo: "",
      showLogin: false,
      showEliminarSolicitud: false,
      rut: "",
      rutFallecido: "",
      data_retiro: false,
      segundoRetiro: false,
      tercerRetiro: false,
      bono: true,
      ejecutivoLogueado: false,
      showLoginEjecutivo: false,
      bloquear: false,
      sitekey: process.env.REACT_APP_sitekey,
      backURLValidSolicitudEnCurso: process.env.REACT_APP_backURLValidSolicitudEnCurso,
      backURLValidToken: process.env.REACT_APP_backURLValidToken,
      bajarRetiro: process.env.REACT_APP_BAJAR_RETIROS
    }
  }

  async componentWillMount() {

    // console.log("IMPRIMIENDO VARIABLES DE ENTORNO ", process.env.NODE_ENV, process.env.REACT_APP_sitekey, process.env.REACT_APP_backURLValidSolicitudEnCurso, process.env.REACT_APP_backURLValidToken)

    var token = sessionStorage.getItem('token');
    if (sessionStorage.getItem("rutBeneficiario")) {
      this.setState({ rut: sessionStorage.getItem("rutBeneficiario") })
    }

    if (sessionStorage.getItem("rutCausante")) {
      this.setState({ rutFallecido: sessionStorage.getItem("rutCausante") })
    }
    if (!token || token == null) {
      return
    }

    await fetch(this.state.backURLValidToken, {
      method: 'POST',
      body: JSON.stringify({ "token": token }),
      headers: {
        'Content-Type': 'application/json'
      }
    }).then(res => res)
      .then((response) => {
        if (response.status === 400) {
          this.setState({ showLoginEjecutivo: true, ejecutivoLogueado: false })
        } else {
          this.setState({ showLoginEjecutivo: false, ejecutivoLogueado: true })
        }
      })
      .catch((error) => {
        this.setState({ showLoginEjecutivo: true, ejecutivoLogueado: false })
      })
  }

  LimpiarRut = (rutvalor) => {
    var rutv = rutvalor.replace(/\./g, "");
    rutv = rutv.replace(/\-/g, "");
    rutv = rutv.padStart(10, "0");
    return rutv;
  }



  ModalInformativoparentCallBackClose = () => {
    this.setState({ showModalInformativo: false })
  }

  HandlerRUT = (e) => {
    var rut = e.target.value
    this.setState({ rut: format(rut), infoRut: "", nroSerie: "", fechaNacimiento: "" })
  }



  HandlerRutFallecido = (e) => {
    var rutFallecido = e.target.value
    this.setState({ rutFallecido: format(rutFallecido), infoRutFallecido: "" })
  }




  NewHOC = (PassedComponent) => {
    return class extends React.Component {
      render() {
        return (
          <div>
            <PassedComponent {...this.props} />
          </div>
        )
      }
    }
  }


  HandlerSubmit = async () => {

    var { rut, rutFallecido } = this.state
    // console.log(rut, rutFallecido)
    if (rut == "" || rutFallecido == "") {
      this.setState({ showModalInformativo: true, textModalInformativo: "Favor ingrese los datos solicitados" })
      return;
    }
    this.setState({ bloquear: true })
    this.recaptcha.execute();

  }

  HandlerSubmitEliminarSolicitud = async () => {
    var { rut, rutFallecido } = this.state
    sessionStorage.setItem("rutBeneficiario", rut)
    sessionStorage.setItem("rutCausante", rutFallecido)
    this.setState({ primerRetiro: true, segundoRetiro: true, tercerRetiro: true, bono: true, showEliminarSolicitud: true })

  }

  HandlerRecaptcha = async () => {
    var { rut, rutFallecido } = this.state
    var token = await this.recaptcha.getResponse();
    await fetch(this.state.backURLValidSolicitudEnCurso, {
      method: 'POST',
      body: JSON.stringify({ "rut": this.LimpiarRut(rut), "rutFallecido": this.LimpiarRut(rutFallecido), "captchaToken": token }),
      headers: {
        'Content-Type': 'application/json'
      }
    }).then(res => res.json())
      .then((response) => {
        //  console.log("response retiro ", response)
        sessionStorage.setItem("rutBeneficiario", rut)
        sessionStorage.setItem("rutCausante", rutFallecido)
        if (response.data_retiro[0].data_retiro == "NO_REGISTRADO_BASE_SO") {
          //     console.log("USUARIO NO REGISTRADO EN BD")
          this.setState({ showModalInformativo: true, textModalInformativo: "Los datos ingresados no se encuentran registrados en la base de beneficiarios de sobrevivencia, favor valídelos e intente nuevamente" })
          return
        }
        this.setState({ bloquear: false, data_retiro: response.data_retiro, showLogin: true })

      })
      .catch(() => {
        this.setState({ showModalInformativo: true, textModalInformativo: "En estos momentos tenemos problemas para validar sus datos, favor inténtelo mas tarde" })
        return;
      });
  }

  LoginEjecutivo_ = () => {
    this.setState({ showLoginEjecutivo: true })
  }


  render() {
    const Login = this.NewHOC(_Login);
    const LoginEjecutivoView = this.NewHOC(LoginEjecutivo);
    var bajarRetiro;
    if (this.state.bajarRetiro === 'true')
      bajarRetiro = true;
    else
      bajarRetiro = false
    var { data_retiro } = this.state;
    console.log(bajarRetiro)
    return <div>
      {this.state.showLoginEjecutivo ?
        <LoginEjecutivoView></LoginEjecutivoView>
        :
        this.state.showLogin ?
          <Login data_retiro={data_retiro}></Login>
          :
          this.state.showEliminarSolicitud ?
            <EliminarSolicitud ></EliminarSolicitud>
            :
            <div class="bg">

              <ModalInformativo mostrarModal={this.state.showModalInformativo} texto={this.state.textModalInformativo} parentCallBackInformativo={this.ModalInformativoparentCallBackClose}></ModalInformativo>
              {

                bajarRetiro ?
                  <div className="container " >
                    <div class="row">
                      <div class="col-md-3">
                      </div>
                      <div class="acctions-login-container">
                        <div class="bajarRetiro">
                          <h2 class="bajarRetiro_h2">Página no disponible</h2>
                          <p1 class="bajarRetiro_p1">Te informamos que el plazo
                            para realizar tu solicitud
                            de tercer retiro del 10%,
                            terminó el día
                            Jueves 28 de Abril.</p1>
                          <div class="action-button">
                            <button class="btnRegister montoRetiroNumero" id="submitButton" onClick={() => window.open("https://home.afpcapital.cl/", "_self")}>VOLVER AL HOME</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  :
                  <div className="row register-form" style={{ marginTop: "-2px" }} >

                    <div className="container " >

                      <div class="row">

                        <div class="col-md-3">
                        </div>


                        <div class="col-md-8">
                          <br></br>
                          <div class="row "><div class="col-md-12">
                            {this.state.ejecutivoLogueado == false && <img src={IconEjecutivo} style={{ maxWidth: "5%", right: "0px", position: "absolute" }} onClick={() => this.LoginEjecutivo_()} />}
                            <img src={AFPLogo} href="/" onClick={() => this.setState({ indexRedirect: true })}></img>
                          </div></div>

                          <br></br>
                          <div class="row">
                            <div class="col-md-12 text-center">
                              <h1 style={{ fontFamily: "Trebuchet MS,Arial,Helvetica,sans-serif", fontSize: "30px", lineHeight: "100%", color: "#0033A0" }}>Retiro de fondo de pensiones</h1>
                              Beneficiarios de pensión de sobrevivencia
                              <br></br><br></br>
                            </div></div>
                          <div class="row">
                            <div class="col-md-3"></div>
                            <div class="col-md-6">

                              {/* <h3 style={{ fontFamily: "Trebuchet MS,Arial,Helvetica,sans-serif", fontSize: "20px", lineHeight: "100%", color: "#00ACCB" }}>
                        Datos del Beneficiario
                       </h3> */}
                            </div>
                            <div class="col-md-3"></div>
                          </div>
                          <div class="row">
                            <div class="col-md-3"></div>
                            <div class="col-md-6">
                              <label for="basic-url">RUT del beneficiario</label>
                              <div class="input-group mb-3">
                                <input type="email" class="form-control" maxLength="12" value={this.state.rut} onChange={(e) => this.HandlerRUT(e)}></input>
                                <p className="inputdisclaimertext" style={{ color: this.state.rutColorWarning }}>{this.state.infoRut}</p>
                              </div>
                            </div>
                            <div class="col-md-3"></div>
                          </div>

                          <div class="row">
                            <div class="col-md-3"></div>
                            <div class="col-md-6">
                              {/* <h3 style={{ fontFamily: "Trebuchet MS,Arial,Helvetica,sans-serif", fontSize: "20px", lineHeight: "100%", color: "#00ACCB" }}>
                        Datos del Causante
                       </h3> */}
                            </div>
                            <div class="col-md-3"></div>
                          </div>
                          <div class="row">
                            <div class="col-md-3"></div>
                            <div class="col-md-6">
                              <label for="basic-url">RUT del causante</label>
                              <div class="input-group mb-3">
                                <input type="email" className="form-control" maxLength="12" value={this.state.rutFallecido} onChange={(e) => this.HandlerRutFallecido(e)}></input>
                                <p className="inputdisclaimertext" style={{ color: 'rgb(0, 51, 160)', fontSize: '12px' }}>RUT de la persona que originó la pensión (marido, padre, madre, esposa, etc.)</p>
                                <p className="inputdisclaimertext" style={{ color: this.state.rutFallecidoColorWarning }}>{this.state.infoRutFallecido}</p>
                              </div>
                            </div>
                            <div class="col-md-3"></div>
                          </div>
                          <div class="text-center">
                            <BlockUi tag="div" blocking={this.state.bloquear} message="Estamos validando su información, por favor espere..." keepInView>
                              <div class="row">
                                <div class="col-md-4">
                                </div>
                                <div class="col-md-4">

                                  <div class="row">
                                    <div class="col-md-6">
                                      <button className="btnRegister montoRetiroNumero" id="submitButton" onClick={() => this.HandlerSubmit()}>Ingresar</button>
                                    </div>
                                    <div class="col-md-6">
                                      <button className="btnRegister montoRetiroNumero" id="submitButton" onClick={() => this.HandlerSubmitEliminarSolicitud()}>Eliminar Solicitud</button>
                                    </div>
                                    <Recaptcha
                                      ref={ref => this.recaptcha = ref}
                                      sitekey={this.state.sitekey}
                                      onResolved={() => this.HandlerRecaptcha()} />
                                  </div>
                                  <p className="small-text" id="disclaimerInfo" style={{ 'fontSize': '1rem', color: this.state.disclaimerColor, fontWeight: "bold" }}>{this.state.diclaimerEmpty}</p>
                                </div>
                                <div class="col-md-4">
                                </div>
                              </div>
                              <div className="text-center">
                                <div class="row">
                                  <div class="col-md-3"></div>
                                  <div class="col-md-6 text-center">
                                    {this.state.ejecutivoLogueado &&
                                      <p>Ingreso Solicitud Ejecutiva/o</p>

                                    }
                                  </div>
                                  <div class="col-md-3"></div>
                                </div>
                                <div class="row" >
                                  <div class="col-md-12" >
                                    <p className="inputdisclaimertext" style={{ color: 'rgb(0, 51, 160)', fontSize: '12px' }} >
                                      (*) Si existe más de un beneficiario por grupo familiar, se deben ingresar solicitudes individuales.
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </BlockUi>
                          </div>

                        </div>


                        <div class="col-md-1">
                        </div>
                      </div>


                    </div>

                  </div>
              }


              <Footer></Footer>

            </div>


      }
    </div>

  }
}

export default Home;