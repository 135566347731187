import logo from './logo.svg';
import './App.css';
import './bootstrap.min.css'
import Home from './sitios/home/index'
import RetiroGenerico from './sitios/retiro/index'
import BonoSobrevivencia from './sitios/bono/index'
import Bonos from './sitios/home/index_bono'
import LandingEjecutivo from './sitios/ejecutivo/index'

import { BrowserRouter as Router, Route } from "react-router-dom";
function App() {
  return (
    //build-qa-test
    <Router>
      <Route exact path="/" component={Home} />
      <Route path="/retiro10" component={RetiroGenerico} />
      <Route path="/bonos" component={Bonos} />
      <Route path="/bono-sobrevivencia" component={BonoSobrevivencia} />
      <Route path="/ejecutivo" component={LandingEjecutivo} />
    </Router>

  );
}

export default App;
