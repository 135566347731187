import React from "react";
import { Clock, ExclamationCircle, ClockHistory } from 'react-bootstrap-icons';
import AFPLogo from '../../logo_afp.png';
import Recaptcha from 'react-google-invisible-recaptcha';
import ModalInformativo from '../../componentes/modal'
import ModalOpcionGrid from '../../componentes/modalOpcionGrid'
import ModalOpcion from '../../componentes/modalOpcion'
import Footer from "../../componentes/footer";
import ReactHtmlParser from 'react-html-parser';
const moment = require('moment')
const { format } = require('rut.js')

class Home extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showModalInformativo: false,
      showModalOpcion: false,
      showModalOpcionAceptacion: false,
      textModalInformativo: "",
      keyEliminacion: "",
      infoFooter: "",
      disclaimerColor: 'greeen',
      diclaimerEmpty: "",
      tipoRetiro: "",
      tituloModalOpcion: "",
      tituloModalAceptacion: "¿Esta seguro que desea eliminar la solicitud?",
      textModalOpcionAcepctacion: "Al presionar 'ACEPTO' su solicitud sera eliminada y por tanto debera ingresar una nueva solicitud de retiro",
      opcionModalAceptacion: "ACEPTO",
      displayOpcion: 'block',
      textModalOpcion: [],
      rut: "",
      nroFolio: "",
      infoNroFolio: "",
      optionModal: "",
      data_retiro: [],
      sitekey: process.env.REACT_APP_sitekey,
      backURLValidSolicitudEnCurso: process.env.REACT_APP_backURLValidSolicitudEnCurso,
      backURLObtenerConfiguracionRetiro: process.env.REACT_APP_backURLObtenerConfiguracionRetiro,
      backURLConsultaSolicitud: process.env.REACT_APP_backURLConsultaSolicitud,
      backURLEliminarSolicitud: process.env.REACT_APP_backURLEliminarSolicitud,
      backURLReload: process.env.REACT_APP_backURLReload
    }
  }

  async componentWillMount() {
    await fetch(this.state.backURLObtenerConfiguracionRetiro, {
      method: 'POST',
      body: JSON.stringify({}),
      headers: {
        'Content-Type': 'application/json'
      }
    }).then(resp => resp.json())
      .then((response) => {
        //  console.log(response)
        this.setState({ data_retiro: response.data_retiro })
      })
      .catch((error) => this.setState({ showModalInformativo: true, textModalInformativo: "Ha ocurrido un problema al rescatar la información del sistema, favor vuelve a intentarlo" }))

  }

  LimpiarRut = (rutvalor) => {
    var rutv = rutvalor.replace(/\./g, "");
    rutv = rutv.replace(/\-/g, "");
    rutv = rutv.padStart(10, "0");
    return rutv;
  }



  ModalInformativoparentCallBackClose = () => {
    this.setState({ showModalInformativo: false })
  }

  HandlerRUT = (e) => {
    var rut = e.target.value
    this.setState({ rut: format(rut), infoRut: "", nroSerie: "", fechaNacimiento: "", disableSubmit: true })
  }

  HandlerNroFolio = (e) => {
    var nroFolio = e.target.value
    this.setState({ nroFolio: nroFolio, infoNroFolio: "" })
  }



  NewHOC = (PassedComponent) => {
    return class extends React.Component {
      render() {
        return (
          <div>
            <PassedComponent {...this.props} />
          </div>
        )
      }
    }
  }


  HandlerSubmit = async () => {
    var { rut, nroFolio, tipoRetiro } = this.state
    this.setState({ disclaimerColor: 'green', disclaimerEmpty: "Validando información.." })
    if (rut == "" || nroFolio == "" || tipoRetiro == 0) {
      this.setState({ showModalInformativo: true, textModalInformativo: "Uno o mas datos se encuentran vacios o sin asignar, favor verifique e intente nuevamente" })
      return
    }
    let coleccionRetiro = this.state.data_retiro.find(x => x.numeroretiro.toString() == this.state.tipoRetiro).coleccionSolicitudesBeneficiario
    //console.log("coleccion retiro ", coleccionRetiro)
    await fetch(this.state.backURLConsultaSolicitud, {
      method: 'POST',
      body: JSON.stringify({ "rut": this.LimpiarRut(rut), "nroFolio": nroFolio, "coleccion": coleccionRetiro }),
      headers: {
        'Content-Type': 'application/json'
      }
    }).then(res => res.json())
      .then((response) => {
        if (response.objectError) {
          this.setState({ showModalInformativo: true, textModalInformativo: "La solicitud consultada no se encuentra en nuestros registros, favor verifica que la información ingresada es correcta e intentalo nuevamente" })
          return
        }
        let formats = ['DDMMYYYY', 'DDMMYY'];
        let fechaSolicitud = moment(response.fechaSolicitud, formats).format("YYYYMMDD")
        let fechaActual = moment().format("YYYYMMDD")
        let fechaSolicitudCoincideConFechaActual = moment(fechaSolicitud).isSame(fechaActual)

        this.setState({
          showModalOpcion: true, optionModal: fechaSolicitudCoincideConFechaActual ? "Eliminar Solicitud" : "",
          displayOpcion: fechaSolicitudCoincideConFechaActual ? "block" : "none",
          tituloModalOpcion: "Datos de la Solicitud",
          infoFooter: fechaSolicitudCoincideConFechaActual ? "" : "La solicitud consultada fue ingresada en una fecha anterior a la actual por lo que no es posible eliminarla",
          keyEliminacion: response.rut + response.rutFallecido,
          textModalOpcion: [
            { atributo: "Número de solicitud", valor: response.folioSolicitud },
            { atributo: "Fecha de solicitud", valor: response.fechaSolicitud },
            { atributo: "RUT beneficiario", valor: response.rut },
            { atributo: "Número de serie beneficiario", valor: response.nroSerie },
            { atributo: "Nombre beneficiario", valor: response.nombre + ' ' + response.apellido },
            { atributo: "Fecha de nacimiento beneficiario", valor: response.fechaNacimiento },
            { atributo: "Email beneficiario", valor: response.email },
            { atributo: "Direccion beneficiario", valor: response.direccion },
            { atributo: "Telefono beneficiario", valor: response.telefono },
            { atributo: "RUT causante", valor: response.rutFallecido },
            { atributo: "RUT tutor", valor: response.rutTutor },
            { atributo: "Número de serie tutor", valor: response.nroSerieTutor },
            { atributo: "Nombre tutor", valor: response.nombreTutor },
            { atributo: "Porcentaje de retiro", valor: response.porcentajeRetiro },
            { atributo: "Medio de pago", valor: response.medioPago },
            { atributo: "Banco", valor: response.banco },
            { atributo: "Tipo de cuenta", valor: response.tipoCuenta },
            { atributo: "Número de cuenta", valor: response.nroCuenta },
            { atributo: "Posee deduda de pensión", valor: response.deudaPension },
            { atributo: "¿Es autoridad publica?", valor: response.EsAutoridadPublica }
          ]

        })
      }).catch((error) => console.log("error ", error))
  }


  HandlerSubmitCancelar = () => {
    window.location = this.state.backURLReload
  }

  HandlerRecaptcha = async () => {


    var { rut, rutFallecido } = this.state
    var token = await this.recaptcha.getResponse();
    await fetch(this.state.backURLValidSolicitudEnCurso, {
      method: 'POST',
      body: JSON.stringify({ "rut": this.LimpiarRut(rut), "rutFallecido": this.LimpiarRut(rutFallecido), "captchaToken": token }),
      headers: {
        'Content-Type': 'application/json'
      }
    }).then(res => res.json())
      .then((response) => {
        sessionStorage.setItem("rutBeneficiario", rut)
        sessionStorage.setItem("rutCausante", rutFallecido)
        this.setState({ primerRetiro: response.primerRetiro, segundoRetiro: response.segundoRetiro, showLogin: true })

      })
      .catch(() => {
        this.setState({ showModalInformativo: true, textModalInformativo: "En estos momentos tenemos problemas para validar sus datos, favor inténtelo mas tarde" })
        return;
      });
  }

  LoginEjecutivo_ = () => {
    this.setState({ showLoginEjecutivo: true })
  }


  ModalparentCallBackClose = () => {
    this.setState({ showModalOpcion: false })
  }

  ModalparentCallBackCondition = () => {
    this.setState({ showModalOpcionAceptacion: true })
  }

  ModalAceptacionparentCallBackClose = () => {
    this.setState({ showModalOpcionAceptacion: false })
  }

  ModalAceptacionparentCallBackCondition = async () => {
    var { keyEliminacion } = this.state
    let coleccionRetiro = this.state.data_retiro.find(x => x.numeroretiro.toString() == this.state.tipoRetiro).coleccionSolicitudesBeneficiario
    await fetch(this.state.backURLEliminarSolicitud, {
      method: 'POST',
      body: JSON.stringify({ "key": keyEliminacion, tipoRetiro: this.state.tipoRetiro, coleccion: coleccionRetiro }),
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then((response) => {
        if (response.status != 200) {
          this.setState({
            textModalOpcionAcepctacion: `En este momento tenemos problemas para procesar su solicitud, favor vuelta a intentarlo más tarde`
          })
        } else {
          this.setState({
            tituloModalAceptacion: "Solicitud Eliminada",
            textModalOpcionAcepctacion: `Su solicitud fue eliminada correctamente. En breve será redirigido al sitio principal donde podrá ingresar una nueva solicitud`,
            opcionModalAceptacion: "",
            showModalOpcion: false,
            displayOpcion: 'none'
          })

          setTimeout(() => {
            window.location = this.state.backURLReload
          }, 10000);
        }
      })
      .catch((error) => console.log("Fallo exitosamente ", error))


  }
  HandlerTipoRetiro = (e) => {
    //console.log("seleccion ", e.target.value)
    this.setState({ tipoRetiro: e.target.value })
  }


  render() {
    return <div>
      <div class="bg">
        <ModalInformativo mostrarModal={this.state.showModalInformativo} texto={this.state.textModalInformativo} parentCallBackInformativo={this.ModalInformativoparentCallBackClose}></ModalInformativo>
        <ModalOpcionGrid infoFooter={this.state.infoFooter} mostrarModal={this.state.showModalOpcion} titulo={this.state.tituloModalOpcion} texto={this.state.textModalOpcion} opcion={this.state.optionModal} parentCallBack={this.ModalparentCallBackClose} parentCallbackCondition={this.ModalparentCallBackCondition} displayOpcion={this.state.displayOpcion} titulo={this.state.tituloModalOpcion}></ModalOpcionGrid>
        <ModalOpcion mostrarModal={this.state.showModalOpcionAceptacion} titulo={this.state.tituloModalAceptacion} texto={this.state.textModalOpcionAcepctacion} opcion={this.state.opcionModalAceptacion} parentCallBack={this.ModalAceptacionparentCallBackClose} parentCallbackCondition={this.ModalAceptacionparentCallBackCondition} displayOpcion={this.state.displayOpcion}></ModalOpcion>
        <div className="row register-form" style={{ marginTop: "-2px" }} >

          <div className="container " >
            <div class="row">

              <div class="col-md-3">
              </div>


              <div class="col-md-8">
                <br></br>
                <div class="row "><div class="col-md-12">

                  <img src={AFPLogo} href="/" onClick={() => this.setState({ indexRedirect: true })}></img>
                </div></div>

                <br></br>
                <div class="row">
                  <div class="col-md-12 text-center">
                    <h1 style={{ fontFamily: "Trebuchet MS,Arial,Helvetica,sans-serif", fontSize: "30px", lineHeight: "100%", color: "#0033A0" }}> Eliminar Solicitud de pensión de sobrevivencia</h1>






                    <br></br>
                  </div></div>
                <div id="alertas">
                  <div class="row">
                    <div class="col-md-3"></div>
                    <div class="col-md-6">
                      <div class="alert alert-primary" role="alert">
                        <p><small><Clock size={20} />  Puedes eliminar la Solicitud si registra algún dato erróneo o bien, si usted no ingresó esta solicitud.</small> </p>
                        <p><small><ExclamationCircle size={20} />  Por favor tenga en cuenta que si su número de solicitud se compone de menos de 18 dígitos, debe anteponer un cero para ingresar.</small> </p>
                        <p><small><ExclamationCircle size={20} />  Si eres afiliado e ingresaste tu solicitud desde el extranjero por favor ingresa desde la pestaña de extranjero.</small> </p>
                      </div>
                      <div class="col-md-3"></div>
                    </div>

                  </div>
                  <div class="row">
                    <div class="col-md-3"></div>
                    <div class="col-md-6">
                      <div class="alert alert-danger" role="alert">
                        <small><p><ClockHistory size={20} />  Recuerda solo puedes eliminar la solicitud el mismo día de creación hasta las 23:59 hrs. Si deseas eliminar posterior a este plazo, escríbenos <a target="_blank" href="https://www.afpcapital.cl/Paginas/escribenos.aspx">AQUÍ</a>.</p>  </small>
                      </div>
                    </div>
                    <div class="col-md-3"></div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-3"></div>
                  <div class="col-md-6">

                    {/* <h3 style={{ fontFamily: "Trebuchet MS,Arial,Helvetica,sans-serif", fontSize: "20px", lineHeight: "100%", color: "#00ACCB" }}>
                        Datos del Beneficiario
                       </h3> */}
                  </div>
                  <div class="col-md-3"></div>
                </div>
                <div class="row">
                  <div class="col-md-3"></div>
                  <div class="col-md-6">
                    <label for="basic-url">RUT del beneficiario</label>
                    <div class="input-group mb-3">
                      <input type="email" class="form-control" maxLength="12" value={this.state.rut} onChange={(e) => this.HandlerRUT(e)}></input>
                      <p className="inputdisclaimertext" style={{ color: this.state.rutColorWarning }}>{this.state.infoRut}</p>
                    </div>
                  </div>
                  <div class="col-md-3"></div>
                </div>

                <div class="row">
                  <div class="col-md-3"></div>
                  <div class="col-md-6">

                  </div>
                  <div class="col-md-3"></div>
                </div>
                <div class="row">
                  <div class="col-md-3"></div>
                  <div class="col-md-6">
                    <label for="basic-url">Número de solicitud 19 Dígitos (folio)</label>
                    <div class="input-group mb-3">
                      <input type="email" className="form-control" maxLength="19" value={this.state.nroFolio} onChange={(e) => this.HandlerNroFolio(e)} ></input>

                      <p className="inputdisclaimertext" style={{ color: this.state.nroFolioColorWarning }}>{this.state.infoNroFolio}</p>
                    </div>
                  </div>
                  <div class="col-md-3"></div>
                </div>
                <div class="row">
                  <div class="col-md-3"></div>
                  <div class="col-md-6" style={{ display: this.state.bancoDisplay }}>
                    <label className="">Tipo de retiro</label>
                    <select className="custom-select" id="comboTipoCuenta" onChange={(e) => this.HandlerTipoRetiro(e)}>
                      <option value="0" selected>Seleccionar</option>
                      {this.state.data_retiro.map((element) => {
                        return <option id={element.numeroretiro} value={element.numeroretiro}>{element.numeroretiro}</option>
                      })}
                    </select>
                  </div>
                  <div class="col-md-3"></div>
                </div>
                <br></br>
                <div class="text-center">

                  <div class="row">
                    <div class="col-md-4">
                    </div>
                    <div class="col-md-4">

                      <div class="row">
                        <div class="col-md-12">
                          <button className="btnRegister montoRetiroNumero" id="submitButton" onClick={() => this.HandlerSubmit()}>Consultar</button>
                          <button className="btnRegister montoRetiroNumero" id="submitButton" onClick={() => this.HandlerSubmitCancelar()}>Cancelar</button>
                          <p className="small-text" id="disclaimerInfo" style={{ 'fontSize': '1rem', color: this.state.disclaimerColor, fontWeight: "bold" }}>{this.state.diclaimerEmpty}</p>
                        </div>
                        <Recaptcha
                          ref={ref => this.recaptcha = ref}
                          sitekey={this.state.sitekey}
                          onResolved={() => this.HandlerRecaptcha()} />
                      </div>

                    </div>


                    <div class="col-md-4">
                    </div>

                  </div>
                </div>

              </div>


              <div class="col-md-1">
              </div>
            </div>







          </div>
        </div>
        <Footer></Footer>
      </div>



    </div>

  }
}

export default Home;