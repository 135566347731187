
import React from "react";
import { BrowserRouter as Router, Route, Redirect } from "react-router-dom";
import _Solicitud from './landing_solicitud_bono'
import AFPLogo from '../../logo_afp.png';
import InputMask from 'react-input-mask';
import Recaptcha from 'react-google-invisible-recaptcha';
import ModalInformativo from '../../componentes/modal'
import moment from "moment";
import Footer from "../../componentes/footer";
const { format } = require('rut.js')

class PreSolicitudBono extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            showSolicitud: false,
            nombre: "",
            apellido: "",
            rut: "",
            correo: "",
            correoValidacion: "",
            celular: "",
            rutFallecido: "",
            ptjeRetiro: "",
            medioPago: "",
            display: 'none',
            diclaimerEmpty: "",
            disclaimerColor: "red",
            infoRut: "",
            infoNombre: "",
            infoApellido: "",
            infoApellidoPaternoTutor: "",
            infoApellidoMaternoTutor: "",
            infoMail: "",
            infoMailValidacion: "",
            infoCelular: "",
            infoRutFallecido: "",
            infoPtjeRetiro: "",
            bancos: ['BANCO DE CHILE', 'BANCO INTERNACIONAL', 'SCOTIABANK CHILE',
                'BANCO DE CRÉDITO E INVERSIONES', 'BANCO BICE', 'HSBC BANK CHILE', 'BANCO SANTANDER CHILE',
                'ITAÚ CORPBANCA', 'BANCO SECURITY', 'BANCO FALABELLA', 'BANCO RIPLEY',
                'BANCO CONSORCIO', 'SCOTIABANK AZUL', 'BANCO BTG PACTUAL CHILE', 'BANCO ESTADO', 'COOPEUCH'],
            bancoDisplay: 'none',
            bancoSelected: "",
            nroSerie: "",
            nroSerieConfirm: "",
            infoNroSerieConfirm: "",
            cuentas: ['Cta. Rut', 'Cta. Corriente', 'Cta. de Ahorro', 'Cta. vista', 'Cta. de Ahorro a plazo', 'Chequera Electrónica'],
            nroCuenta: "",
            tipoCuenta: "",
            displayNroCuenta: 'none',
            fechaNacimiento: "",
            infoFechaNacimiento: '',
            clientIP: "",
            diclaimerValidacion: "",
            displayLoading: "none",
            disableSubmit: false,
            ejecutivoLogueado: false,
            infoNroSerie: "",
            displayNroCuentaHeroes: "none",
            nroCuentaHeroes: "",
            infoNroCuentaHeroe: "",
            maxlengthCuenta: 12,
            infoDireccion: "",
            direccion: "",
            rutTutor: "",
            apellidoPaternoTutor: "",
            apellidoMaternoTutor: "",
            infoRutTutor: "",
            nroSerieTutor: "",
            infoNroSerietutor: "",
            nombreTutor: "",
            infoNombreTutor: "",
            displayTutorData: 'none',
            rutColorWarning: "red",
            rutFallecidoColorWarning: 'red',
            infoFechaNacimientoColor: 'red',
            deudaPension: "",
            infoPension: "",
            autoridadPublica: "NO",
            displayDeudaPension: "none",
            colorInfoNumeroSerie: "red",
            colorInfoNumeroSerieTutor: "red",
            colorInfoNumeroSerieConfirm: "red",
            textModalInformativo: "",
            showModalInformativo: false,
            sitekey: process.env.REACT_APP_sitekey,
            backURLValidFechaNacimiento: process.env.REACT_APP_backURLValidFechaNacimientoBono,
            backURLValidRutNroSerie: process.env.REACT_APP_backURLValidRutNroSerie,
            backURLValidToken: process.env.REACT_APP_backURLValidToken
        }
    }

    async componentDidMount() {
        var token = sessionStorage.getItem('token');
        if (sessionStorage.getItem("rutBeneficiario")) {
            this.setState({ rut: sessionStorage.getItem("rutBeneficiario") })
        }

        if (sessionStorage.getItem("rutCausante")) {
            this.setState({ rutFallecido: sessionStorage.getItem("rutCausante") })
        }
        if (!token || token == null) {
            return
        }

        await fetch(this.state.backURLValidToken, {
            method: 'POST',
            body: JSON.stringify({ "token": token }),
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(res => res)
            .then((response) => {
                if (response.status === 400) {
                    this.setState({ showLoginEjecutivo: true, ejecutivoLogueado: false })
                } else {
                    this.setState({ showLoginEjecutivo: false, ejecutivoLogueado: true })
                }
            })
            .catch((error) => {
                this.setState({ showLoginEjecutivo: true, ejecutivoLogueado: false })
            })
    }



    ModalInformativoparentCallBackClose = () => {
        this.setState({ showModalInformativo: false })
    }

    LoginEjecutivo = () => {
        this.setState({ showLoginEjecutivo: true })
    }

    LimpiarRut = (rutvalor) => {
        var rutv = rutvalor.replace(/\./g, "");
        rutv = rutv.replace(/\-/g, "");
        rutv = rutv.padStart(10, "0");
        return rutv;
    }

    LimpiarFormatoRut = (rutvalor) => {
        var rutv = rutvalor.replace(/\./g, "");
        rutv = rutv.replace(/\-/g, "");
        //rutv = rutv.padStart(10, "0");
        return rutv;
    }

    LimpiarFormatoRutValidSerie = (rutvalor) => {
        var rutv = rutvalor.replace(/\./g, "");
        return rutv;
    }



    HandlerFechaNacimiento = (e) => {
        var fechaNacimiento = e.target.value
        // document.getElementById('comboMedioPago').selectedIndex = 0;
        // document.getElementById('comboBanco').selectedIndex = 0;
        // document.getElementById('comboTipoCuenta').selectedIndex = 0;
        this.setState({ disableSubmit: true, fechaNacimiento: fechaNacimiento, infoFechaNacimiento: "", bancoSelected: "", tipoCuenta: "", nroCuenta: "" })
    }

    HandlerFechaNacimientoBlur = async (e) => {

        this.setState({ infoFechaNacimientoColor: 'green', infoFechaNacimiento: "validando fecha en sistema..." })
        document.getElementById('submitButton').className += " disabled"
        var fechaNacimientoArr = e.target.value.split('/')
        var dia = parseInt(fechaNacimientoArr[0]);
        var mes = parseInt(fechaNacimientoArr[1]);
        var annio = parseInt(fechaNacimientoArr[2]);
        var annioActual = moment().year()
        var mesActual = moment().month() + 1 //0 para enero
        var diaActual = moment().format('D')
        var edad = annioActual - annio
        var mes_nacimiento_mayor_actual = mes > mesActual  //mes actual mayor al mes de fecha de nacimiento
        var mes_nacimiento_igual_actual = mes == mesActual
        var dia_nacimiento_menor_actual = dia < diaActual  //dia actual mayor al dia de fecha de nacimiento

        // console.log(edad, mes_nacimiento_mayor_actual, mes_nacimiento_igual_actual, dia_nacimiento_menor_actual)
        var fechaNacimientoFormat = moment(`${annio}-${mes}-${dia}`).format("DDMMYYYY")
        var fechaNacimientoFormatValidate = moment(`${annio}-${mes}-${dia}`).format("DD-MM-YYYY")
        if (!(dia >= 1 && dia <= 31)) {
            this.setState({ fechaNacimiento: "", infoFechaNacimientoColor: 'red', infoFechaNacimiento: "El dia de nacimiento ingresado debe estar entre 1 y 31" })
            return
        } else if (!(mes >= 1 && mes <= 12)) {
            this.setState({ fechaNacimiento: "", infoFechaNacimientoColor: 'red', infoFechaNacimiento: "El mes de nacimiento ingresado debe estar entre 1 y 12" })
            return
        } else if (annio > annioActual) {
            this.setState({ fechaNacimiento: "", infoFechaNacimientoColor: 'red', infoFechaNacimiento: "El año de nacimiento ingresado no puede ser mayor al año actual" })
            return
        }


        await fetch(this.state.backURLValidFechaNacimiento, {
            method: 'POST',
            body: JSON.stringify({ "rut": this.state.rut, "fechaNacimiento": fechaNacimientoFormatValidate }),
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(res => res)
            .then((response) => {
                if (response.status === 400) {
                    this.setState({ infoFechaNacimientoColor: 'red', fechaNacimiento: "", infoFechaNacimiento: "Estimado beneficiario, la fecha de nacimiento ingresado no se coincide con nuestros registros , por lo que deberá acercarse de manera presencial a una sucursal de nuestra AFP para actualizar y/o regularizar su información" })
                    return;
                } else {
                    document.getElementById('submitButton').classList.remove("disabled")
                    if (edad < 18 && this.state.ejecutivoLogueado) {
                        this.setState({ disableSubmit: false, displayDeudaPension: "none", infoFechaNacimientoColor: "green", infoFechaNacimiento: "", fechaNacimiento: fechaNacimientoFormat, displayTutorData: 'block', rutTutor: "", nombreTutor: "", nroSerieTutor: "" })
                    } else if (edad < 18 && !this.state.ejecutivoLogueado) {
                        this.setState({ disableSubmit: true, displayDeudaPension: "none", infoFechaNacimientoColor: "red", fechaNacimiento: "", rut: "", showModalInformativo: true, textModalInformativo: "Si el beneficiario es menor de 18 años, la solicitud se debe realizar en una sucursal, presentando el certificado de nacimiento con subinscripción. Si el beneficiario tiene entre 18 y 24 años y no se encuentra realizando estudios, no podrá realizar el retiro.", displayTutorData: 'none', rutTutor: "", nombreTutor: "", nroSerieTutor: "" })
                    } else if (edad == 18 && this.state.ejecutivoLogueado) {
                        this.setState({ disableSubmit: false, displayDeudaPension: "none", infoFechaNacimientoColor: "green", infoFechaNacimiento: "", fechaNacimiento: fechaNacimientoFormat, displayTutorData: 'block', rutTutor: "", nombreTutor: "", nroSerieTutor: "" })
                    }
                    //caso 1 igual a 18 años pero el mes de nac es menor al mes actual
                    else if (edad == 18 && mes_nacimiento_mayor_actual) {
                        //  console.log("caso 1 mes nacimiento menor al actual, no cumple condicion 18 años")
                        this.setState({ disableSubmit: true, displayDeudaPension: "none", infoFechaNacimientoColor: "red", fechaNacimiento: "", rut: "", showModalInformativo: true, textModalInformativo: "Si el beneficiario es menor de 18 años, la solicitud se debe realizar en una sucursal, presentando el certificado de nacimiento con subinscripción. Si el beneficiario tiene entre 18 y 24 años y no se encuentra realizando estudios, no podrá realizar el retiro.", displayTutorData: 'none', rutTutor: "", nombreTutor: "", nroSerieTutor: "" })
                    }
                    //caso 3 igual a 18 años mismo mes de nac dias menores al actual
                    else if (edad == 18 && mes_nacimiento_igual_actual && !dia_nacimiento_menor_actual) {
                        // console.log("igual a 18 años mismo mes de nac dias menores al actual")
                        this.setState({ disableSubmit: true, displayDeudaPension: "none", infoFechaNacimientoColor: "red", fechaNacimiento: "", rut: "", showModalInformativo: true, textModalInformativo: "Si el beneficiario es menor de 18 años, la solicitud se debe realizar en una sucursal, presentando el certificado de nacimiento con subinscripción. Si el beneficiario tiene entre 18 y 24 años y no se encuentra realizando estudios, no podrá realizar el retiro.", displayTutorData: 'none', rutTutor: "", nombreTutor: "", nroSerieTutor: "" })
                    }
                    else {
                        this.setState({ disableSubmit: false, displayDeudaPension: "block", infoFechaNacimientoColor: "green", infoFechaNacimiento: "", fechaNacimiento: fechaNacimientoFormat, displayTutorData: 'none', rutTutor: "", nombreTutor: "", nroSerieTutor: "" })
                    }
                }
            })
            .catch((error) => {
                this.setState({ infoFechaNacimientoColor: 'red', fechaNacimiento: "", infoFechaNacimiento: "Estimado afiliado, en estos momentos tenemos problemas para validar sus datos, favor vuelva a intentarlo." })
                return;
            });
    }



    HandlerNroSerieTutorConfirm = (e) => {
        var serieConfirm = e.target.value
        if (/[^A-Za-z0-9]+/.test(serieConfirm)) {
            return false
        }
        this.setState({ nroSerieTutorConfirm: serieConfirm, infoNroSerietutorConfirm: "", disableSubmit: true })
    }


    HandlerLetterNroSerieTutorBlurConfirm = async () => {

        var { nroSerieTutor, nroSerieTutorConfirm } = this.state
        // console.log(nroSerieTutor, nroSerieTutorConfirm)
        document.getElementById('submitButton').className += " disabled"
        this.setState({ colorInfoNumeroSerieTutorConfirm: 'green', infoNroSerietutorConfirm: "validando informacón número de serie" })
        if (nroSerieTutor !== nroSerieTutorConfirm) {
            this.setState({ nroSerieTutorConfirm: "", colorInfoNumeroSerieTutorConfirm: 'red', infoNroSerietutorConfirm: "El número de serie ingresado no coincide con el original" })
            return
        }
        document.getElementById('submitButton').classList.remove("disabled")
        this.setState({ disableSubmit: false, colorInfoNumeroSerieTutorConfirm: 'red', infoNroSerietutorConfirm: "" })

    }

    HandlerNroSerieTutor = (e) => {
        var serie = e.target.value
        if (/[^A-Za-z0-9]+/.test(serie)) {
            return false
        }
        this.setState({ nroSerieTutor: serie, infoNroSerietutor: "", disableSubmit: true })
    }



    HandlerLetterNroSerieTutorBlur = async () => {
        var { nroSerieTutor, rutTutor } = this.state
        document.getElementById('submitButton').className += " disabled"
        this.setState({ colorInfoNumeroSerieTutor: 'green', infoNroSerietutor: "validando informacón número de serie" })

        if (!rutTutor || rutTutor == "") {
            this.setState({ nroSerieTutor: "", colorInfoNumeroSerieTutor: 'red', infoNroSerietutor: "favor ingrese el rut del Tutor legal para continuar" })
            return;
        }

        const letras = Array.from('ABCDEFGHIJKLMNOPQRSTUVWXYZ')
        var nroSeriearray = Array.from(nroSerieTutor)
        var contador = 0
        letras.map((letra) => {
            var count = nroSeriearray.filter(x => x.toUpperCase() == letra.toUpperCase()).length
            if (count >= 1) {
                contador += 1
            }
        })

        if (contador > 1) { //mas de una letra
            this.setState({ nroSerieTutor: "", colorInfoNumeroSerieTutor: 'red', infoNroSerietutor: "El número de serie no puede contener mas de una letra" })
        } else if (contador == 1) { //una letra
            if (!/^[a-zA-Z()]+$/.test(nroSerieTutor.charAt(0))) { //validacion de letra al principio de la cadena
                this.setState({ nroSerieTutor: "", colorInfoNumeroSerieTutor: 'red', infoNroSerietutor: "La letra debe ir al principio de su número de serie" })
                return
            } else if (nroSerieTutor.length < 7 || nroSerieTutor.length > 10) {
                this.setState({ nroSerieTutor: "", colorInfoNumeroSerieTutor: 'red', infoNroSerietutor: "El largo del número de serie no puede ser menor a 7 ni mayor a 10 digitos" })
                return
            }
        } else if (nroSerieTutor.length < 7 || nroSerieTutor.length > 10) {
            this.setState({ nroSerieTutor: "", colorInfoNumeroSerieTutor: 'red', infoNroSerietutor: "El largo del número de serie no puede ser menor a 7 ni mayor a 10 digitos" })
            return
        }


        await fetch(this.state.backURLValidRutNroSerie, {
            method: 'POST',
            body: JSON.stringify({ "rut": this.LimpiarFormatoRutValidSerie(rutTutor), "serie": nroSerieTutor }),
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        }).then(res => res.json())
            .then((response) => {
                if (response.status !== "OK") {
                    this.setState({ nroSerieTutor: "", nroSerieTutorConfirm: "", colorInfoNumeroSerieTutor: 'red', infoNroSerietutor: "En estos momentos tenemos problemas para validar la información ingresada, favor intentar nuevamente" })
                    return;
                } else if (response.verificacion !== "V") {
                    this.setState({ nroSerieTutor: "", nroSerieTutorConfirm: "", colorInfoNumeroSerieTutor: 'red', infoNroSerietutor: "Estimado afiliado, El número de serie ingresado no es válido o no corresponde al rut de beneficiario, por favor verifique la información e intentelo nuevamente" })
                    return
                } else {
                    document.getElementById('submitButton').classList.remove("disabled")
                    this.setState({ disableSubmit: false, colorInfoNumeroSerieTutor: 'red', infoNroSerietutor: "" })
                }
            })
            .catch((error) => {
                this.setState({ nroSerieTutor: "", nroSerieTutorConfirm: "", colorInfoNumeroSerieTutor: 'red', infoNroSerietutor: "En estos momentos tenemos problemas para validar la información ingresada, favor intentar nuevamente" })
                return;
            });
    }

    HandlerNroSerie = (e) => {
        var serie = e.target.value
        if (/[^A-Za-z0-9]+/.test(serie)) {
            return false
        }
        this.setState({ nroSerie: serie, infoNroSerie: "", disableSubmit: true })

    }

    HandlerConfirmNroSerie = (e) => {
        var numeroSerieConfirmacion = e.target.value
        if (/[^A-Za-z0-9]+/.test(numeroSerieConfirmacion)) {
            return false
        }
        this.setState({ nroSerieConfirm: numeroSerieConfirmacion, infoNroSerieConfirm: "", disableSubmit: true })

    }

    HandlerLetterNroConfirmSerieBlur = async () => {
        var { nroSerieConfirm, nroSerie } = this.state

        if (nroSerie !== nroSerieConfirm) {
            this.setState({ nroSerieConfirm: "", colorInfoNumeroSerieConfirm: 'red', infoNroSerieConfirm: "El número de serie ingresado no coincide con el original" })
            return;
        }
        document.getElementById('submitButton').classList.remove("disabled")
        this.setState({ diclaimerEmpty: "", disableSubmit: false })
    }

    HandlerLetterNroSerieBlur = async () => {
        var { nroSerie, rut } = this.state
        document.getElementById('submitButton').className += " disabled"
        this.setState({ colorInfoNumeroSerie: 'green', infoNroSerie: "validando informacion número de serie..." })

        if (!rut || rut == "") {
            this.setState({ nroSerie: "", colorInfoNumeroSerie: 'red', infoNroSerie: "Estimado afiliado , favor ingrese rut de beneficiario para continuar" });
            return;
        }

        const letras = Array.from('ABCDEFGHIJKLMNOPQRSTUVWXYZ')
        var nroSeriearray = Array.from(nroSerie)
        var contador = 0
        letras.map((letra) => {
            var count = nroSeriearray.filter(x => x.toUpperCase() == letra.toUpperCase()).length
            if (count >= 1) {
                contador += 1
            }
        })

        if (contador > 1) { //mas de una letra
            this.setState({ nroSerie: "", colorInfoNumeroSerie: 'red', infoNroSerie: "El número de serie no puede contener mas de una letra" })
        } else if (contador == 1) { //una letra
            if (!/^[a-zA-Z()]+$/.test(nroSerie.charAt(0))) { //validacion de letra al principio de la cadena
                this.setState({ nroSerie: "", colorInfoNumeroSerie: 'red', infoNroSerie: "La letra debe ir al principio de su número de serie" })
                return
            } else if (nroSerie.length < 7 || nroSerie.length > 10) {
                this.setState({ nroSerie: "", colorInfoNumeroSerie: 'red', infoNroSerie: "El largo del número de serie no puede ser menor a 7 ni mayor a 10 digitos" })
                return
            }
        } else if (nroSerie.length < 7 || nroSerie.length > 10) {
            this.setState({ nroSerie: "", colorInfoNumeroSerie: 'red', infoNroSerie: "El largo del número de serie no puede ser menor a 7 ni mayor a 10 digitos" })
            return
        }

        await fetch(this.state.backURLValidRutNroSerie, {
            method: 'POST',
            body: JSON.stringify({ "rut": this.LimpiarFormatoRutValidSerie(rut), "serie": nroSerie }),
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        }).then(res => res.json())
            .then((response) => {
                if (response.status !== "OK") {
                    this.setState({ nroSerie: "", colorInfoNumeroSerie: 'red', infoNroSerie: "En estos momentos tenemos problemas para validar la información ingresada, favor intentar nuevamente" })
                    return;
                } else if (response.verificacion !== "V") {
                    this.setState({ nroSerie: "", colorInfoNumeroSerie: 'red', infoNroSerie: "Estimado afiliado, El número de serie ingresado no es válido o no corresponde al rut de beneficiario, por favor verifique la información e intentelo nuevamente" })
                    return
                } else {
                    document.getElementById('submitButton').classList.remove("disabled")
                    this.setState({ disableSubmit: false, colorInfoNumeroSerie: 'red', infoNroSerie: "", nroSerieConfirm: "" })
                }
            })
            .catch((error) => {
                document.getElementById('submitButton').classList.remove("disabled")
                this.setState({ disableSubmit: false, colorInfoNumeroSerie: 'red', infoNroSerie: "", nroSerieConfirm: "" })
                //this.setState({ nroSerie: "", colorInfoNumeroSerie: 'red', infoNroSerie: "En estos momentos tenemos problemas para validar la información ingresada, favor intentar nuevamente" })
                return;
            });
    }



    HandlerRutTutor = (e) => {
        var rut = e.target.value
        this.setState({ rutTutor: format(rut), infoRutTutor: "", nroSerieTutor: "" })
    }

    handlerRutTutorBlur = (e) => {
        var { rutTutor, rut, rutFallecido } = this.state

        if (rutTutor == rut || rutTutor == rutFallecido) {
            this.setState({ rutTutor: "", infoRutTutor: "El rut del tutor no puede ser igual al rut del beneficiario y/o causante" })
            return;
        }

        const letras = Array.from('ABCDEFGHIJKLMNOPQRSTUVWXYZ')
        var rutarray = Array.from(rutTutor)
        var contador = 0
        letras.map((letra) => {
            var count = rutarray.filter(x => x.toUpperCase() == letra.toUpperCase()).length
            if (count >= 1) {
                contador += count
            }
        })

        if (contador > 1) { //mas de una letra
            this.setState({ rutTutor: "", infoRutTutor: "El rut ingresado no puede contener mas de una letra K" })
            return;
        } else if (isNaN(rutTutor.charAt(0))) {
            this.setState({ rutTutor: "", infoRutTutor: "El rut no puede comenzar por una letra" })
            return
        } else if (rutTutor.length < 11) {
            this.setState({ rutTutor: "", infoRutTutor: "EL rut debe tener un largo de 8 digitos incluyendo el digito verificador" })
            return
        } else {
            this.setState({ infoRutTutor: "" })
        }
    }

    HandlerRUT = (e) => {
        var rut = e.target.value
        this.setState({ rut: format(rut), infoRut: "", nroSerie: "", fechaNacimiento: "", disableSubmit: true })
    }




    HandlerNombreTutor = (e) => {
        var nombreTutor = e.target.value
        if (!/^[a-zA-Z()]+$/.test(nombreTutor.replace(/\s/g, ''))) {
            this.setState({ nombreTutor: "", infoNombreTutor: "El nombre del tutor no puede contener números" })
            return;
        }
        this.setState({ nombreTutor, infoNombreTutor: "" })
    }


    HandlerRutFallecido = (e) => {
        var rutFallecido = e.target.value
        this.setState({ disableSubmit: true, rutFallecido: format(rutFallecido), infoRutFallecido: "" })
    }





    HandlerRecaptcha = async () => {

    }



    HandlerSubmit = async () => {

        document.getElementById('submitButton').className += " disabled"
        // if (this.state.autoridadPublica == "SI") {
        //     this.setState({ autoridadPublica: "SI", disableSubmit: true, showModalInformativo: true, textModalInformativo: "No puedes continuar con tu solicitud de retiro porque declaraste ser autoridad pública" })
        //     return;
        // }

        // this.setState({ displayLoading: "block", diclaimerEmpty: "Validando los datos ingresados por favor espere...." })
        const { rut, nroSerie, nroSerieConfirm, fechaNacimiento, rutTutor, nombreTutor, apellidoPaternoTutor, apellidoMaternoTutor, nroSerieTutor, nroSerieTutorConfirm, rutFallecido } = this.state


        if (this.state.displayTutorData === "block") {
            if (rut === "" || nroSerie === "" || nroSerieConfirm === "" || fechaNacimiento === "" || rutTutor === "" || nombreTutor === "" || apellidoPaternoTutor === "" || apellidoMaternoTutor === "" || nroSerieTutor === "" || nroSerieTutorConfirm === "" || rutFallecido === "") {
                this.setState({ disclaimerColor: "red", diclaimerEmpty: "Favor verifica que la información ingresada sea correcta y no se encuentren campos vacios para continuar con la solicitud" })
                return
            }
        } else {
            if (rut === "" || nroSerie === "" || nroSerieConfirm === "" || fechaNacimiento === "" || rutFallecido === "") {
                this.setState({ disclaimerColor: "red", diclaimerEmpty: "Favor verifica que la información ingresada sea correcta y no se encuentren campos vacios para continuar con la solicitud" })
                return
            }
        }
        document.getElementById('submitButton').classList.remove("disabled")
        this.setState({ showSolicitud: true })
    }

    NewHOC = (PassedComponent) => {
        return class extends React.Component {
            render() {
                return (
                    <div>
                        <PassedComponent {...this.props} />
                    </div>
                )
            }
        }
    }

    // HandlerCheckBoxAutoridad = (e) => {
    //     var option = e.target.value
    //     if (option == 1) {
    //         document.getElementById('submitButton').className += " disabled"
    //         this.setState({ autoridadPublica: "SI", disableSubmit: true, showModalInformativo: true, textModalInformativo: "No puedes continuar con tu solicitud de retiro porque declaraste ser autoridad pública" })
    //     } else {
    //         document.getElementById('submitButton').classList.remove("disabled")
    //         this.setState({ autoridadPublica: "NO", disableSubmit: false, showModalInformativo: false, textModalInformativo: "" })
    //     }

    // }




    HandlerApellidoPaternoTutor = (e) => {
        var apellidoPaternoTutor = e.target.value
        if (!/^[a-zA-Z()]+$/.test(apellidoPaternoTutor.replace(/\s/g, ''))) {
            this.setState({ apellidoPaternoTutor: "", infoApellidoPaternoTutor: "Tus apellidos no pueden contener numeros" })
            return;
        }
        this.setState({ apellidoPaternoTutor, infoApellidoPaternoTutor: "" })
    }


    HandlerApellidoMaternoTutor = (e) => {
        var apellidoMaternoTutor = e.target.value
        if (!/^[a-zA-Z()]+$/.test(apellidoMaternoTutor.replace(/\s/g, ''))) {
            this.setState({ apellidoMaternoTutor: "", infoApellidoMaternoTutor: "Tus apellidos no pueden contener numeros" })
            return;
        }
        this.setState({ apellidoMaternoTutor, infoApellidoMaternoTutor: "" })
    }

    render() {
        if (this.state.indexRedirect) {
            window.location = process.env.REACT_APP_backURLReloadBono
        }
        const Solicitud = this.NewHOC(_Solicitud);
        // const LoginEjecutivo = this.NewHOC(_LoginEjecutivo)
        return <div>
            {this.state.showSolicitud == true ?
                <Solicitud rutBeneficiario={this.state.rut}
                    fechaNacimientoBeneficiario={this.state.fechaNacimiento}
                    nroSerieBeneficiario={this.state.nroSerie}
                    rutTutor={this.state.rutTutor}
                    nombreTutor={this.state.nombreTutor}
                    apellidoMaternoTutor={this.state.apellidoMaternoTutor}
                    apellidoPaternoTutor={this.state.apellidoPaternoTutor}
                    nroSerieTutor={this.state.nroSerieTutor}
                    rutCausante={this.state.rutFallecido}
                    MostrarDeudaPension={this.state.displayDeudaPension}
                    autoridadPublica={this.state.autoridadPublica}
                    saldobono1={this.props.saldobono1 ? this.props.saldobono1 : 0}
                >
                </Solicitud>
                :
                <div class="bg">
                    <ModalInformativo mostrarModal={this.state.showModalInformativo} texto={this.state.textModalInformativo} parentCallBackInformativo={this.ModalInformativoparentCallBackClose}></ModalInformativo>
                    <div className="row register-form" style={{ marginTop: "-2px" }} >

                        <div className="container " >
                            <div class="row">

                                <div class="col-md-3">
                                </div>


                                <div class="col-md-8">
                                    <br></br>
                                    <div class="row "><div class="col-md-12"> <img src={AFPLogo} href="/" onClick={() => this.setState({ indexRedirect: true })}></img></div></div>
                                    <br></br>
                                    <div class="row">
                                        <div class="col-md-12 text-center">
                                            <h1 style={{ fontFamily: "Trebuchet MS,Arial,Helvetica,sans-serif", fontSize: "30px", lineHeight: "100%", color: "#0033A0" }}>Acceso a Solicitud Bono de Cargo Fiscal Beneficiarios de Sobrevivencia</h1>
                                            Si eres beneficiario de pensión de sobrevivencia , déjanos tus datos para gestionar tu solicitud.
                                            <br></br><br></br><br></br>
                                        </div></div>
                                    <div class="row">
                                        {this.state.ejecutivoLogueado &&
                                            <p>Ingreso Solicitud Ejecutiva/o</p>

                                        }
                                        <div class="col-md-12">
                                            <br></br>
                                            <h3 style={{ fontFamily: "Trebuchet MS,Arial,Helvetica,sans-serif", fontSize: "20px", lineHeight: "100%", color: "#00ACCB" }}>
                                                Datos del Beneficiario
                                            </h3>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <label for="basic-url">RUT del beneficiario</label>
                                            <div class="input-group mb-3">
                                                <input type="email" className="form-control" maxLength="12" readOnly={true} value={this.state.rut} onChange={(e) => this.HandlerRUT(e)} ></input>
                                                <p className="inputdisclaimertext" style={{ color: this.state.rutColorWarning }}>{this.state.infoRut}</p>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <label for="basic-url">Fecha Nacimiento Beneficiario</label>
                                            <div class="input-group mb-3">
                                                <InputMask className="form-control" mask="99/99/9999" maskChar="_" placeholder="DD/MM/YYYY" value={this.state.fechaNacimiento} onChange={(e) => this.HandlerFechaNacimiento(e)} onBlur={(e) => this.HandlerFechaNacimientoBlur(e)} />
                                                <p className="inputdisclaimertext" style={{ color: 'rgb(0, 51, 160)', fontSize: '12px' }}>Si el beneficiario es menor de 18 años, la solicitud se debe realizar en una sucursal, presentando los certificados correspondientes. Si el beneficiario tiene entre 18 y 24 años y no se encuentra realizando estudios, no podrá realizar el retiro</p>
                                                <p className="inputdisclaimertext" style={{ color: this.state.infoFechaNacimientoColor }}>{this.state.infoFechaNacimiento}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <label for="basic-url">N° de serie del carnet</label>
                                            <div class="input-group mb-3">
                                                <input type="email" onCopy={(e) => { e.preventDefault(); return false }} onPaste={(e) => { e.preventDefault(); return false }} className="form-control" maxLength="10" placeholder="" value={this.state.nroSerie} onChange={(e) => this.HandlerNroSerie(e)} onBlur={(e) => this.HandlerLetterNroSerieBlur(e)}></input>
                                                <p className="inputdisclaimertext" style={{ color: this.state.colorInfoNumeroSerie }}>{this.state.infoNroSerie}</p>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <label for="basic-url">Confirme N° de serie del carnet</label>
                                            <div class="input-group mb-3">
                                                <input type="email" class="form-control" id="basic-url" aria-describedby="basic-addon3" onCopy={(e) => { e.preventDefault(); return false }} maxLength="10" onPaste={(e) => { e.preventDefault(); return false }} autoComplete="off" value={this.state.nroSerieConfirm} onChange={(e) => this.HandlerConfirmNroSerie(e)} onBlur={(e) => this.HandlerLetterNroConfirmSerieBlur(e)}></input>
                                                <p className="inputdisclaimertext" style={{ color: this.state.colorInfoNumeroSerieConfirm }}>{this.state.infoNroSerieConfirm}</p>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div class="row" >
                                        <div class="col-md-6">
                                            Declaración jurada para autoridad pública (Articulo 38 bis).
                          <p className="inputdisclaimertext" style={{ color: 'rgb(0, 51, 160)', fontSize: '12px', textAlign: 'justify' }}>{"¿Es usted una autoridad pública tal como Presidente de la República, Senadores, Diputados, Ministros de Estado, Gobernadores Regionales, funcionarios de exclusiva confianza del Jefe de Estado que señalan los números 7 y 10 del artículo 32 de la Constitución Política del EstadoJefe de Servicio y los contratados sobre la base de honorarios que asesoren directamente a las autoridades gubernativas antes indicadas?"}</p>
                                        </div>
                                        <div class="col-md-6">
                                            <div className="form-check">
                                                <input className="form-check-input" type="radio" name="radiopublico" id="gridRadios5" value="1" onClick={(option) => this.HandlerCheckBoxAutoridad(option)}></input>
                                                <label className="" >
                                                    Sí, declaro ser autoridad pública.
                              </label>
                                            </div>
                                            <div className="form-check">
                                                <input className="form-check-input" type="radio" name="radiopublico" id="gridRadios6" value="2" onClick={(option) => this.HandlerCheckBoxAutoridad(option)}></input>
                                                <label className="" >
                                                    No, declaro no ser autoridad pública.
                              </label>
                                            </div>
                                        </div>
                                    </div> */}
                                    <div id="tutor" style={{ display: this.state.displayTutorData }}>
                                        <div class="row">
                                            <div class="col-md-12">
                                                <br></br>
                                                <h3 style={{ fontFamily: "Trebuchet MS,Arial,Helvetica,sans-serif", fontSize: "20px", lineHeight: "100%", color: "#00ACCB" }}>
                                                    Datos del Tutor (si aplica)
                                                </h3>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-6">
                                                <label for="basic-url">RUT Tutor</label>
                                                <div class="input-group mb-3">
                                                    <input className="form-control" maxLength="12" value={this.state.rutTutor} onChange={(e) => this.HandlerRutTutor(e)} onBlur={(e) => this.handlerRutTutorBlur(e)}></input>
                                                    <p className="inputdisclaimertext">{this.state.infoRutTutor}</p>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <label for="basic-url">Nombre del Tutor</label>
                                                <div class="input-group mb-3">
                                                    <input type="email" className="form-control" value={this.state.nombreTutor} onChange={(e) => this.HandlerNombreTutor(e)}></input>
                                                    <p className="inputdisclaimertext">{this.state.infoRutTutor}</p>
                                                </div>
                                            </div>

                                        </div>
                                        <div class="row">
                                            <div class="col-md-6">
                                                <label for="basic-url">Apellido Paterno Tutor</label>
                                                <div class="input-group mb-3">
                                                    <input className="form-control" maxLength="12" value={this.state.apellidoPaternoTutor} onChange={(e) => this.HandlerApellidoPaternoTutor(e)} onBlur={(e) => this.handlerRutTutorBlur(e)}></input>
                                                    <p className="inputdisclaimertext">{this.state.infoApellidoPaternoTutor}</p>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <label for="basic-url">Apellido Materno del Tutor</label>
                                                <div class="input-group mb-3">
                                                    <input type="email" className="form-control" value={this.state.apellidoMaternoTutor} onChange={(e) => this.HandlerApellidoMaternoTutor(e)}></input>
                                                    <p className="inputdisclaimertext">{this.state.infoApellidoMaternoTutor}</p>
                                                </div>
                                            </div>

                                        </div>
                                        <div class="row">
                                            <div class="col-md-6">
                                                <label for="basic-url">N° de serie del carnet</label>
                                                <div class="input-group mb-3">
                                                    <input className="form-control" maxLength="10" value={this.state.nroSerieTutor} onChange={(e) => this.HandlerNroSerieTutor(e)} onBlur={(e) => this.HandlerLetterNroSerieTutorBlur(e)}></input>
                                                    <p className="inputdisclaimertext" style={{ color: this.state.colorInfoNumeroSerieTutor }}>{this.state.infoNroSerietutor}</p>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <label for="basic-url">Confirme N° de serie del carnet</label>
                                                <div class="input-group mb-3">
                                                    <input className="form-control" maxLength="10" value={this.state.nroSerieTutorConfirm} onChange={(e) => this.HandlerNroSerieTutorConfirm(e)} onBlur={(e) => this.HandlerLetterNroSerieTutorBlurConfirm(e)}></input>
                                                    <p className="inputdisclaimertext" style={{ color: this.state.colorInfoNumeroSerieTutorConfirm }}>{this.state.infoNroSerietutorConfirm}</p>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div class="row">
                                        <div class="col-md-12">
                                            <br></br>
                                            <h3 style={{ fontFamily: "Trebuchet MS,Arial,Helvetica,sans-serif", fontSize: "20px", lineHeight: "100%", color: "#00ACCB" }}>
                                                Datos del Causante
                                            </h3>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <label for="basic-url">RUT del Causante</label>
                                            <div class="input-group mb-3">
                                                <input type="email" className="form-control" maxLength="12" readOnly={true} value={this.state.rutFallecido} onChange={(e) => this.HandlerRutFallecido(e)} ></input>
                                                <p className="inputdisclaimertext" style={{ color: 'rgb(0, 51, 160)', fontSize: '12px' }}>El causante corresponde a la persona que originó los ahorros para financiar la pensión de sobrevivencia (Padre, madre, esposo o esposa)</p>
                                                <p className="inputdisclaimertext" style={{ color: this.state.rutFallecidoColorWarning }}>{this.state.infoRutFallecido}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="text-center">

                                        <div class="row">
                                            <div class="col-md-4">
                                            </div>
                                            <div class="col-md-4">

                                                <div class="row">
                                                    <div class="col-md-12">
                                                        <button className="btnRegister montoRetiroNumero" id="submitButton" disabled={this.state.disableSubmit} onClick={() => this.HandlerSubmit()}>Ingresar</button>
                                                    </div>
                                                    <Recaptcha
                                                        ref={ref => this.recaptcha = ref}
                                                        sitekey={this.state.sitekey}
                                                        onResolved={() => this.HandlerRecaptcha()} />
                                                </div>
                                                <p className="small-text" id="disclaimerInfo" style={{ 'fontSize': '1rem', color: this.state.disclaimerColor, fontWeight: "bold" }}>{this.state.diclaimerEmpty}</p>
                                            </div>
                                            <div class="col-md-4">
                                            </div>
                                        </div>


                                    </div>

                                </div>


                                <div class="col-md-1">
                                </div>
                            </div>







                        </div>
                    </div>
                    <Footer></Footer>
                </div>



            }
        </div>
    }
}

export default PreSolicitudBono;