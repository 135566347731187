import React from 'react';
// import './App.css';
// import './bootstrap.min.css';
import { BrowserRouter as Router, Route, useHistory } from "react-router-dom";
import PreSolicitud from './landing_presolicitud_bono';
const { format } = require('rut.js')


export default class TercerRetiro extends React.Component {


  constructor(props) {

    super(props);
    // console.log("props" , this,this.props)
    this.state = {
      rut: "",
      saldobono1 : this.props.location? this.props.location.saldobono1: 0
    }
  }

  NewHOC = (PassedComponent) => {
    return class extends React.Component {
        render() {
            return (
                <div>
                    <PassedComponent {...this.props} />
                </div>
            )
        }
    }
}

  render() {
    const PreSolicitud_ = this.NewHOC(PreSolicitud);
    return <Router>
    <PreSolicitud_ saldobono1={this.state.saldobono1}></PreSolicitud_>
    </Router>

  }
}

