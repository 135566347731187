import React from "react";
import { Modal, Button,Row,Col,Container } from 'react-bootstrap';
import ReactDOM from "react-dom";
import { ExclamationCircle, ExclamationTriangle  } from 'react-bootstrap-icons';

class ModalFunction extends React.Component {

  state = {
    showModal: false,
  }

    // handleClose = () => {console.log("Se seteara en false showModal"); this.setState({ showModal: false })};

  render() {
     const { titulo, texto, opcion, mostrarModal} = this.props;
    const modal = document.querySelector("#modalOpcion");
    return ReactDOM.createPortal(<>
      <Modal  show={mostrarModal} onHide={this.props.parentCallBack}>
        <Modal.Header closeButton>
          <Modal.Title>{titulo}</Modal.Title>
        </Modal.Header>
        <Modal.Body >
        <div class='alert alert-warning' role='alert'><ExclamationTriangle size={20} /> {texto}</div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={this.props.parentCallBack}>
            Cerrar
            </Button>
          <Button variant="primary" onClick={this.props.parentCallbackCondition} style={{display: this.props.displayOpcion}}>
            {opcion}
          </Button>
        </Modal.Footer>
      </Modal>
    </>, modal);
  }



}

export default ModalFunction;