import React from 'react';
// import './App.css';
// import './bootstrap.min.css';
import { BrowserRouter as Router, Route, useHistory } from "react-router-dom";
import PreSolicitud from './landing_presolicitud';



export default class RetiroGenerico extends React.Component {

  constructor(props) {

    super(props);
    // console.log("PROPS INDEX RETIRO ", this.props.location)
  }


  NewHOC = (PassedComponent) => {
    return class extends React.Component {

      render() {
        return (
          <div>
            <PassedComponent {...this.props} />
          </div>
        )
      }
    }
  }

  render() {
    const PreSolicitud_ = this.NewHOC(PreSolicitud);
    return <Router>
      <PreSolicitud_ propiedades={this.props.location}></PreSolicitud_>
    </Router>

  }
}

