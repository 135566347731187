import React from "react";
import { Card } from 'react-bootstrap';
import { Link } from "react-router-dom";
import AFPLogo from '../../logo_afp.png';
import Footer from "../../componentes/footer";
import ReactHtmlParser from 'react-html-parser';

class Home extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            puedeHacerBono: false,
            showModalInformativo: true,
            textModalInformativo: ""
        }
    }

    ModalInformativoparentCallBackClose = () => {
        this.setState({ showModalInformativo: false })
    }


    componentDidMount() {
        if (this.props) {
            if (this.props.ejecutivoLogueado) {
                this.setState({
                    puedeHacerBono: true,
                    saldobono1: this.props.saldobono1 ? this.props.saldobono1 : 0
                })
            } else {
                this.setState({
                    puedeHacerBono: this.props.bono,
                    saldobono1: this.props.saldobono1 ? this.props.saldobono1 : 0
                })
            }

        }
    }

    render() {
        return <div class="bg">
            <div class="row">
                <div class="col-md-2"></div>
                <div class="col-md-9">
                    <div class="text-center"><br></br>
                        <h1 class="display-4"><img src={AFPLogo} href="/"></img></h1>

                        <p class="lead">Portal para el retiro de bonos de sobrevivencia</p>
                    </div>
                    <br></br>
                    <div class="row">
                        <div class="col-md-4"></div>
                        <div class="col-md-4 text-center">
                            <div class="container">
                                <div class="card-deck mb-12 text-center">

                                    <Card class="rounded" style={{ width: '18rem' }} >
                                        <Card.Header style={this.state.puedeHacerBono ? { opacity: '1', color: "white", fontSize: "20px", fontWeight: "bold", backgroundColor: "#00aec7" } : { opacity: '0.25', color: "white", fontSize: "20px", fontWeight: "bold", backgroundColor: "#0033A0" }} >Bono retiro de fondos de sobrevivencia</Card.Header>
                                        <Card.Body>
                                            <Card.Title><div style={this.state.puedeHacerBono ? { opacity: '1' } : { opacity: '0.25' }} class="circulo"><h2>1</h2></div></Card.Title>
                                            {this.state.puedeHacerBono ?
                                                <Card.Text style={this.state.puedeHacerBono ? { opacity: '1', fontSize: "16px" } : { opacity: '0.25', fontSize: "18px" }}>
                                                    <p style={{ textAlign: "justify" }}> {"El Bono de Cargo Fiscal se pagará dentro de 15 días corridos desde el ingreso de la solicitud.".padEnd(150, "\xa0")}</p>
                                                </Card.Text> :
                                                <Card.Text style={{ opacity: '1', fontSize: "18px", color: "#0033A0", fontSize: "20px", fontWeight: "bold" }}>No es posible continuar. Ya existe una solicitud de retiro en proceso para el RUT ingresado o este no se encuentra en la base de beneficiarios.</Card.Text>
                                            }

                                        </Card.Body>
                                        <footer style={{ backgroundColor: '#FFFFFF', marginLeft: '10px', marginRight: '10px' }}>
                                            {this.state.puedeHacerBono ?
                                                <Link to={{ pathname: '/bono-sobrevivencia', saldobono1: this.state.saldobono1 }}> <button type="button" class="btnRegister montoRetiroNumero">Entrar</button></Link> :
                                                <span></span>
                                            }
                                        </footer>
                                    </Card>

                                </div>


                            </div>
                            <br></br>

                        </div>
                        <div class="col-md-4">

                        </div>
                    </div>


                </div>

                <div class="col-md-1">

                </div>


            </div>

            <div>

            </div>


            <Footer></Footer>

        </div>
    }
}

export default Home;