import React from 'react';
// import './App.css';
// import './bootstrap.min.css';
import Recaptcha from 'react-google-invisible-recaptcha';
import { BrowserRouter as Router, Route } from "react-router-dom";
import { Redirect } from 'react-router'
import AFPLogo from '../../logo_afp.png';
import IndexHome from '../home/index'
import Footer from '../../componentes/footer'
const { format } = require('rut.js')

export default class LandingEjecutivo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: false,
      disableSubmit: false,
      rutEjecutivo: "",
      claveEjecutivo: "",
      rutColorWarning: "green",
      claveColorWarning: "green",
      colorSubmit: "green",
      sitekey: process.env.REACT_APP_sitekey,
      URL_LOGIN_EJECUTIVO: process.env.REACT_APP_URL_LOGIN_EJECUTIVO,
      URL_HOST: process.env.REACT_APP_URL_HOST,
      URL_PATH_BONO: process.env.REACT_APP_URL_PATH_BONO,
      URL_PATH_RETIRO: process.env.REACT_APP_URL_PATH_RETIRO
    }
  }

  LimpiarRut = (rutvalor) => {
    var rutv = rutvalor.replace(/\./g, "");
    rutv = rutv.replace(/\-/g, "");
    // rutv = rutv.padStart(10, "0");
    return rutv;
  }

  HandlerRUTEjecutivo = (e) => {
    var rut = e.target.value
    this.setState({ rutEjecutivo: format(rut), infoRutEjecutivo: "", clave: "", infoSubmit: "" })
  }

  HandlerRutEjecutivoBlur = (e) => {
    var { rutEjecutivo } = this.state

    //validacion de rut existente en base proporcionada
    this.setState({ disableSubmit: true, rutColorWarning: 'green', infoRut: "validando rut en sistema...." })
    document.getElementById('submitButton').className += " disabled"
    const letras = Array.from('ABCDEFGHIJKLMNOPQRSTUVWXYZ')
    var rutarray = Array.from(rutEjecutivo)
    var contador = 0
    letras.map((letra) => {
      var count = rutarray.filter(x => x.toUpperCase() == letra.toUpperCase()).length
      if (count >= 1) {
        contador += count
      }
    })

    if (contador > 1) { //mas de una letra
      this.setState({ rutColorWarning: 'red', rutEjecutivo: "", infoRutEjecutivo: "El rut ingresado no puede contener mas de una letra K" })
      return;
    } else if (isNaN(rutEjecutivo.charAt(0))) {
      this.setState({ rutColorWarning: 'red', rutEjecutivo: "", infoRutEjecutivo: "El rut no puede comenzar por una letra" })
      return
    } else {
      document.getElementById('submitButton').classList.remove("disabled")
      this.setState({ disableSubmit: false, infoRutEjecutivo: "" })
    }
  }


  HandlerClaveEjecutivo = (e) => {
    var clave = e.target.value
    this.setState({ claveEjecutivo: clave, infoClaveEjecutivo: "", infoSubmit: "" })
  }


  HandlerSubmit = async () => {
    document.getElementById('submitButton').className += " disabled"
    this.setState({ disableSubmit: true })
    this.recaptcha.execute();
  }

  NewHOC = (PassedComponent) => {
    return class extends React.Component {
      render() {
        return (
          <div>
            <PassedComponent {...this.props} />
          </div>
        )
      }
    }
  }


  HandlerRecaptcha = async () => {
    var token = await this.recaptcha.getResponse();
    var { rutEjecutivo, claveEjecutivo } = this.state
    this.setState({ colorSubmit: 'green', infoSubmit: "validando datos en el  sistema...." })
    rutEjecutivo = this.LimpiarRut(rutEjecutivo)

    await fetch(this.state.URL_LOGIN_EJECUTIVO, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ "rutEjecutivo": rutEjecutivo, "claveEjecutivo": claveEjecutivo, "captchaToken": token }),
    }).then(res => res.json())
      .then(response => {
        document.getElementById('submitButton').classList.remove("disabled")
        if (response.rechazo === true) {
          this.setState({ colorSubmit: "red", infoSubmit: "favor verifique que su rut y/o clave son correctos" })
          return;
        } else {
          this.setState({ colorSubmit: 'green', infoSubmit: "redirigiendo al sitio principal..." })
          sessionStorage.setItem('token', response.token);
          setTimeout(() => {
            this.setState({ redirect: true, infoSubmit: "" })
          }, 5000);
        }
      })
      .catch((error) => {
        document.getElementById('submitButton').classList.remove("disabled")
        this.setState({ colorSubmit: "red", infoSubmit: "En estos momentos tenemos problemas para procesar su solicitud, favor intente nuevamente en unos minutos" })
        return;
      });
  }



  render() {
    return (
      <Router>
        {this.state.redirect ?
          window.location.host === this.state.URL_HOST &&
            window.location.pathname === "/bonos" ?
            window.location = this.state.URL_PATH_BONO
            :
            window.location = this.state.URL_PATH_RETIRO
          :
          <div class="bg">
            <div className="row register-form" style={{ marginTop: "-2px" }} >

              <div className="container " >
                <div class="row">
                  <div class="col-md-3"></div>
                  <div class="col-md-8">
                    <br></br>
                    <div class="row "><div class="col-md-12"> <img src={AFPLogo} href="/" onClick={() => window.location = this.state.URL_PATH_RETIRO}></img ></div></div>
                    <br></br>
                    <div class="row">
                      <div class="col-md-12 text-center">
                        <h1 style={{ fontFamily: "Trebuchet MS,Arial,Helvetica,sans-serif", fontSize: "30px", lineHeight: "100%", color: "#0033A0" }}>Ejecutivos</h1>
                        Ingreso de Ejecutivos
                        <br></br><br></br><br></br>
                      </div></div>
                    <div class="row">
                      <div class="col-md-2"></div>
                      <div class="col-md-4">
                        <label className="" >RUT/DNI Ejecutivo</label>
                      </div>
                      <div class="col-md-4">
                        <input type="email" className="form-control" placeholder="" maxLength="12" value={this.state.rutEjecutivo} onChange={(e) => this.HandlerRUTEjecutivo(e)} onBlur={() => this.HandlerRutEjecutivoBlur()}></input>
                        <p className="inputdisclaimertext" style={{ color: this.state.rutColorWarning }}>{this.state.infoRutEjecutivo}</p>
                      </div>
                      <div class="col-md-2"></div>
                    </div>
                    <div class="row">
                      <div class="col-md-2"></div>
                      <div class="col-md-4">
                        <label className="" >Clave</label>
                      </div>
                      <div class="col-md-4">
                        <input type="password" className="form-control" placeholder="" value={this.state.claveEjecutivo} onChange={(e) => this.HandlerClaveEjecutivo(e)}></input>
                        <p className="inputdisclaimertext" style={{ color: this.state.claveColorWarning }}>{this.state.infoClave}</p>
                      </div>
                      <div class="col-md-2"></div>
                    </div>
                    <div class="row">
                      <div class="col-md-4">
                      </div>
                      <div class="col-md-4">
                        <button className="btnRegister montoRetiroNumero" id="submitButton" disabled={this.state.disableSubmit} onClick={() => this.HandlerSubmit()}>Ingresar</button>
                        <Recaptcha
                          ref={ref => this.recaptcha = ref}
                          sitekey={this.state.sitekey}
                          onResolved={() => this.HandlerRecaptcha()} />
                      </div>
                      <div class="col-md-4">
                      </div>
                    </div>
                    <div class="text-center">
                      <p id="infoSubmit" style={{ color: "red", fontSize: "16px", fontWeight: "bold" }}>{this.state.infoSubmit}</p>
                    </div>
                  </div>
                  <div class="col-md-1"></div>
                </div>
              </div>
            </div><br></br>
            <br></br>
            <br></br>
            <br></br>
            <Footer></Footer>

          </div>

        }



      </Router>
    )
  }
}

