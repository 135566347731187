import React from "react";


var style = {
    backgroundColor: "#F8F8F8",
    borderTop: "1px solid #E7E7E7",
    textAlign: "left",
    padding: "20px",
    position: "fixed",
    left: "0",
    bottom: "0",
    height: "85px",
    width: "100%",
    color: "#0033A0",
    zIndex: -1
}

var phantom = {
    display: 'block',
    padding: '20px',
    height: '60px',
    width: '100%',
}

class Footer extends React.Component {




    render() {
        return <>
            <div style={phantom} />
            <div style={style}>
                <div class="row">
                    <div class="col-md-10">
                        <small style={{ color: "black" }}>Para un mejor funcionamiento del sitio, le recomendamos utilizar navegador Chrome o Firefox en sus últimas versiones.</small>
                        <br></br>
                        <small style={{ color: "black" }}><b>600 6600 900 – 23 293 4655</b> Lun a Jue de 9 a 18 hrs. Vier de 9 a 16 hrs.</small>
                        <br></br>
                        <br></br>
                    </div>
                    <div class="col-md-2">
                        <a href="https://www.afpcapital.cl/Administracion/Paginas/sucursales.aspx" target="_blank"> <b>Sucursales</b></a>
                    </div>
                </div>
            </div>
        </>

        // <footer class="footer mt-auto py-3">
        //     <div class="container">
        //         <div class="row">
        //             <div class="col-md-10">
        //                 <small>Para un mejor funcionamiento del sitio, le recomendamos utilizar navegador Chrome o Firefox en sus últimas versiones.</small>
        //                 <br></br><small><b>600 6600 900 – 23 293 4655</b> Lun a Jue de 9 a 18 hrs. Vier de 9 a 16 hrs.</small>
        //             </div>
        //             <div class="col-md-2">
        //                 <a href="https://www.afpcapital.cl/Administracion/Paginas/sucursales.aspx" target="_blank"> <b>Sucursales</b></a>
        //             </div>
        //         </div>

        //     </div>
        // </footer>

    }



}

export default Footer;