
import React from "react";
import { BrowserRouter as Router, Route, Redirect } from "react-router-dom";
// import _Solicitud from './solicitud'
import AFPLogo from '../../logo_afp.png';
import Recaptcha from 'react-google-invisible-recaptcha';
import moment from "moment";
import InputMask from 'react-input-mask';
import Home from '../home/index'
import ModalInformativo from '../../componentes/modal'
import ReactHtmlParser from 'react-html-parser';
import Footer from "../../componentes/footer";
import { Alert } from 'react-bootstrap';
const { format } = require('rut.js')

class SolicitudBono extends React.Component {


    constructor(props) {
        super(props);

        this.state = {
            nombre: "",
            apellidoPaterno: "",
            apellidoMaterno: "",
            rut: "",
            correo: "",
            correoValidacion: "",
            celular: "",
            celularConfirm: "",
            rutFallecido: "",
            ptjeRetiro: "",
            medioPago: "",
            display: 'none',
            diclaimerEmpty: "",
            disclaimerColor: "red",
            infoRut: "",
            infoNombre: "",
            infoApellido: "",
            infoMail: "",
            infoMailValidacion: "",
            infoCelular: "",
            infoRutFallecido: "",
            infoPtjeRetiro: "",
            bancos: ['BANCO DE CHILE', 'BANCO INTERNACIONAL', 'SCOTIABANK CHILE',
                'BANCO DE CRÉDITO E INVERSIONES', 'BANCO BICE', 'HSBC BANK CHILE', 'BANCO SANTANDER CHILE',
                'ITAÚ CORPBANCA', 'BANCO SECURITY', 'BANCO FALABELLA', 'BANCO RIPLEY',
                'BANCO CONSORCIO', 'SCOTIABANK AZUL', 'BANCO BTG PACTUAL CHILE', 'BANCO ESTADO', 'COOPEUCH'],
            bancoDisplay: 'none',
            bancoSelected: "",
            nroSerie: "",
            cuentas: ['Cta. Rut', 'Cta. Corriente', 'Cta. de Ahorro', 'Cta. vista', 'Cta. de Ahorro a plazo', 'Chequera Electrónica'],
            nroCuenta: "",
            tipoCuenta: "",
            displayNroCuenta: 'none',
            fechaNacimiento: "",
            infoFechaNacimiento: '',
            clientIP: "",
            diclaimerValidacion: "",
            displayLoading: "none",
            disableSubmit: false,
            infoNroSerie: "",
            displayNroCuentaHeroes: "none",
            nroCuentaHeroes: "",
            infoNroCuentaHeroe: "",
            maxlengthCuenta: 12,
            infoDireccion: "",
            direccion: "",
            rutTutor: "",
            infoRutTutor: "",
            nroSerieTutor: "",
            infoNroSerietutor: "",
            nombreTutor: "",
            infoNombreTutor: "",
            displayTutorData: 'none',
            rutColorWarning: "red",
            rutFallecidoColorWarning: 'red',
            infoFechaNacimientoColor: 'red',
            deudaPension: "SI",
            infoPension: "(*) Si selecciona tener una deuda de pensión de alimentos, el monto solicitado será retenido hasta confirmar la existencia de la medida cautelar.",
            displayDeudaPension: "block",
            colorInfoNumeroSerie: "red",
            colorInfoNumeroSerieTutor: "red",
            ejecutivoLogueado: false,
            nombreEjecutivo: "",
            nroCuentaHeroesConfirm: "",
            infoNroCuentaHeroeconfirm: "",
            indexRedirect: false,
            esAutoridadPublica: "",
            textModalInformativo: "",
            showModalInformativo: false,
            deudaPensionConfirm: "",
            apellidoPaternoTutor: "",
            apellidoMaternoTutor: "",
            terminosConfirm: "",
            saldobono1: "",
            sitekey: process.env.REACT_APP_sitekey,
            backURLSave: process.env.REACT_APP_backURLSaveBono
        }
    }

    componentDidMount() {
        if (this.props && this.props.ejecutivoLogueado === true) {
            this.setState({ ejecutivoLogueado: true, nombreEjecutivo: this.props.nombreEjecutivo })
        } else {
            this.setState({ ejecutivoLogueado: false, nombreEjecutivo: "" })
        }

        if (this.props) {
            this.setState({
                rut: this.props.rutBeneficiario,
                rutFallecido: this.props.rutCausante,
                fechaNacimiento: this.props.fechaNacimientoBeneficiario,
                nroSerie: this.props.nroSerieBeneficiario,
                rutTutor: this.props.rutTutor,
                nombreTutor: this.props.nombreTutor,
                nroSerieTutor: this.props.nroSerieTutor,
                apellidoMaternoTutor: this.props.apellidoMaternoTutor,
                apellidoPaternoTutor: this.props.apellidoPaternoTutor,
                displayDeudaPension: "block",//this.props.MostrarDeudaPension,
                esAutoridadPublica: this.props.autoridadPublica,
                saldobono1: this.props.saldobono1
            })
        }
        fetch('https://geoip-db.com/json/')
            .then(response => response.json())
            .then(response => this.setState({ clientIP: response.IPv4 }))
            .catch(error => this.setState({ clientIP: "127.0.0.1" }))
    }

    LimpiarRut = (rutvalor) => {
        var rutv = rutvalor.replace(/\./g, "");
        rutv = rutv.replace(/\-/g, "");
        rutv = rutv.padStart(10, "0");
        return rutv;
    }

    LimpiarFormatoRut = (rutvalor) => {
        var rutv = rutvalor.replace(/\./g, "");
        rutv = rutv.replace(/\-/g, "");
        //rutv = rutv.padStart(10, "0");
        return rutv;
    }

    LimpiarFormatoRutValidSerie = (rutvalor) => {
        var rutv = rutvalor.replace(/\./g, "");
        return rutv;
    }


    ValidarMail = (email) => {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    HandlerBanco = (e) => {
        var banco = e.target.value
        document.getElementById('comboTipoCuenta').selectedIndex = 0;
        if (banco == 0) {
            this.setState({ bancoSelected: "", tipoCuenta: "", nroCuentaHeroes: "", nroCuenta: "", roCuentaHeroesConfirm: "", nroCuentaConfirm: "" })
            return;
        }

        if (banco == 'BANCO FALABELLA') {
            this.setState({ maxlengthCuenta: 13 })
        } else {
            this.setState({ maxlengthCuenta: 12 })
        }

        if (banco == "BANCO ESTADO") {
            this.setState({ bancoSelected: banco, tipoCuenta: "", nroCuentaHeroes: "", nroCuenta: "", roCuentaHeroesConfirm: "", nroCuentaConfirm: "", cuentas: ['Cta. Rut', 'Cta. Corriente', 'Cta. de Ahorro', 'Cta. vista', 'Cta. de Ahorro a plazo', 'Chequera Electrónica '] })
            return;
        } else if (banco == "COOPEUCH") {
            this.setState({ bancoSelected: banco, tipoCuenta: "", nroCuentaHeroes: "", nroCuenta: "", roCuentaHeroesConfirm: "", nroCuentaConfirm: "", cuentas: ['Cta. vista'] })
            return;
        }
        this.setState({ bancoSelected: banco, tipoCuenta: "", nroCuentaHeroes: "", nroCuenta: "", roCuentaHeroesConfirm: "", nroCuentaConfirm: "", cuentas: ['Cta. Corriente', 'Cta. de Ahorro', 'Cta. vista', 'Cta. de Ahorro a plazo', 'Chequera Electrónica '] })

    }


    HandlerNombre = (e) => {
        var nombre = e.target.value
        if (!/^[a-zA-Z()]+$/.test(nombre.replace(/\s/g, ''))) {
            this.setState({ nombre: "", infoNombre: "Tus nombres no pueden contener numeros" })
            return;
        }
        this.setState({ nombre, infoNombre: "" })
    }


    HandlerNombreTutor = (e) => {
        var nombreTutor = e.target.value
        if (!/^[a-zA-Z()]+$/.test(nombreTutor.replace(/\s/g, ''))) {
            this.setState({ nombreTutor: "", infoNombreTutor: "El nombre del tutor no puede contener números" })
            return;
        }
        this.setState({ nombreTutor, infoNombreTutor: "" })
    }


    HandlerApellidoPaterno = (e) => {
        var apellidoPaterno = e.target.value
        if (!/^[a-zA-Z()]+$/.test(apellidoPaterno.replace(/\s/g, ''))) {
            this.setState({ apellidoPaterno: "", infoApellidoPaterno: "Tus apellidos no pueden contener numeros" })
            return;
        }
        this.setState({ apellidoPaterno, infoApellidoPaterno: "" })
    }



    HandlerApellidoMaterno = (e) => {
        var apellidoMaterno = e.target.value
        if (!/^[a-zA-Z()]+$/.test(apellidoMaterno.replace(/\s/g, ''))) {
            this.setState({ apellidoMaterno: "", infoApellidoMaterno: "Tus apellidos no pueden contener numeros" })
            return;
        }
        this.setState({ apellidoMaterno, infoApellidoMaterno: "" })
    }

    HandlerEmail = (e) => {
        var email = e.target.value
        this.setState({ correo: email, correoValidacion: "", disableSubmit: true })

    }


    HandlerEmailBlur = (e) => {
        var email = this.state.correo
        if (!this.ValidarMail(email)) {
            this.setState({ correo: "", infoMail: "El correo ingresado no tiene el formato correcto xxx@xxx.xx" })
            return
        } else {
            this.setState({ infoMail: "", disableSubmit: false })
        }
    }



    HandlerEmailValidacion = (e) => {
        var emailValidacion = e.target.value
        this.setState({ correoValidacion: emailValidacion, disableSubmit: true })

    }


    HandlerEmailValidationBlur = (e) => {
        var { correo, correoValidacion } = this.state
        if (!this.ValidarMail(correoValidacion)) {
            this.setState({ correoValidacion: "", infoMailValidacion: "El correo ingresado no tiene el formato correcto xxx@xxx.xx" })
            return
        } else if (correo != correoValidacion) {
            this.setState({ correoValidacion: "", infoMailValidacion: "Ambos correso ingresados deben ser iguales" })
            return
        } else {
            this.setState({ infoMailValidacion: "", disableSubmit: false })
        }
    }


    HandlerDireccion = (e) => {

        var direccion = e.target.value
        this.setState({ direccion, disableSubmit: true })
    }

    HandlerDireccionBlur = (e) => {

        var direccion = e.target.value
        if (direccion.length == 0) {
            this.setState({ direccion: "", infoDireccion: "favor ingrese la información solicitada" })
            return
        }
        this.setState({ infoDireccion: "", disableSubmit: false })
    }

    HandlerCelularBlurConfirm = (e) => {
        const { celular, celularConfirm } = this.state
        if (celular !== celularConfirm) {
            this.setState({ celularConfirm: "", infoCelularConfirm: "El número de celular no coincide con el que se ingreso previamente", disableSubmit: true })
            return
        }
        this.setState({ celularConfirm: celular, infoCelularConfirm: "", diclaimerEmpty: "", disableSubmit: false })
    }

    HandlerCelularConfirm = (e) => {
        var celular = e.target.value
        this.setState({ celularConfirm: celular, infoCelularConfirm: "", disableSubmit: false })
    }

    HandlerCelular = (e) => {

        var celular = e.target.value
        if (isNaN(celular)) {
            this.setState({ celular: "", celularConfirm: "", infoCelular: "El valor ingresado debe contener solo números" })
            return
        } else if (celular.charAt(0) != "9") {
            this.setState({ celular: "", celularConfirm: "", infoCelular: "El número de celular debe comenzar con 9  y tener 9 digitos" })
            return
        }
        this.setState({ celular, celularConfirm: "", infoCelular: "", disableSubmit: true })
    }

    HandlerCelularBlur = (e) => {
        var celular = e.target.value
        if (celular.length < 9) {
            this.setState({ celular: "", infoCelular: "El número de celular debe contener 9 digitos" })
            return
        }
        this.setState({ celular, infoCelular: "", disableSubmit: false })
    }

    HandlerRutFallecido = (e) => {
        var rutFallecido = e.target.value
        this.setState({ disableSubmit: true, rutFallecido: format(rutFallecido), infoRutFallecido: "" })
    }



    HandlerCombo = (e) => {
        var option = e.target.value

        if (option == 1) {
            this.setState({ ptjeRetiro: "100", display: 'none' })
        } else {
            this.setState({ ptjeRetiro: "", display: 'block' })
        }
    }

    HandlerCheckBoxPension = (e) => {
        var option = e.target.value
        if (option == 1) {
            this.setState({ deudaPension: "SI", deudaPensionConfirm: "" })
        } else {
            this.setState({ deudaPension: "NO", deudaPensionConfirm: "" })
        }
    }

    HandlerPorcentajeOtro = (e) => {
        var porcentaje = e.target.value

        if (/\D/.test(porcentaje)) {
            this.setState({ ptjeRetiro: "", infoPtjeRetiro: "El valor ingresado debe contener solo números" })
        } else if (porcentaje == 0 || porcentaje >= 100) {
            this.setState({ ptjeRetiro: "", infoPtjeRetiro: "El valor ingresado debe estar entre 1 y 99" })
        }
        else {
            this.setState({ ptjeRetiro: porcentaje, infoPtjeRetiro: "" })
        }
    }

    ModalInformativoparentCallBackClose = () => {
        this.setState({ showModalInformativo: false })
    }


    HandlerMedioPago = (e) => {
        var medioPago = e.target.value

        if (medioPago == 0) {
            document.getElementById('comboBanco').selectedIndex = 0;
            document.getElementById('comboTipoCuenta').selectedIndex = 0;
            this.setState({ medioPago: "", displayNroCuentaHeroes: 'none', bancoDisplay: 'block', bancoSelected: "", tipoCuenta: "", nroCuentaHeroes: "", nroCuenta: "", roCuentaHeroesConfirm: "", nroCuentaConfirm: "" })
            return
        }
        else if (medioPago == 1) {
            medioPago = "Cuenta Bancaria"
            document.getElementById('comboBanco').selectedIndex = 0;
            document.getElementById('comboTipoCuenta').selectedIndex = 0;
            this.setState({
                displayNroCuentaHeroes: 'none', bancoDisplay: 'block', nroCuentaHeroes: "", nroCuentaHeroesConfirm: "", showModalInformativo: true,
                textModalInformativo:
                    ReactHtmlParser(`<ul>
                    <li>Valide el N° y tipo de cuenta bancaria antes de ingresar tu solicitud. </li>
                    <li>Ingrese los datos de tu cuenta bancaria personal, sin puntos ni guión.</li>
                    <li>La cuenta bancaria es sólo numérica y tiene un largo máximo de 14 dígitos.  </li>
                    <li>No ingrese el número cuenta de especial creado por su banco para recibir el primer, segundo o tercer retiro del 10% ya que el depósito será rechazado por la insitución financiera.</li>
                    <li> El N° de cuenta bancaria, no es el N° de su tarjeta física. Si no conoce el N° de cuenta consulte directamente con su Banco. </li>
                    <li> Por su seguridad, no está autorizado el uso de cuentas bipersonales ni digitales.  </li>
                    <li>Una vez ingresada la cuenta bancaria, haga clic en el recuadro "Acepto las restricciones para cuentas bancarias bipersonales y digitales" para continuar. </li>
                    <li>Si el N° de cuenta o tipo de cuenta no coincide con la información que posee su Banco, su solicitud podría ser rechazada.</li>
                    <li>Si no tiene una cuenta a su nombre, puede seleccionar Cuenta de Ahorro Voluntario (Cuenta 2), para crear una cuenta de ahorro automáticamente, sin costos de apertura ni comisiones.</li>
            </ul>`)

            })
        }
        else if (medioPago == 2) {
            this.setState({ displayNroCuentaHeroes: 'none', bancoDisplay: 'none', bancoSelected: "", tipoCuenta: "", nroCuentaHeroes: "", nroCuenta: "", roCuentaHeroesConfirm: "", nroCuentaConfirm: "", displayNroCuenta: 'none' })
            medioPago = "Cuenta de Ahorro Voluntario (Cuenta 2)"
            document.getElementById('comboBanco').selectedIndex = 0;
            document.getElementById('comboTipoCuenta').selectedIndex = 0;
        }
        // else {
        //     document.getElementById('comboBanco').selectedIndex = 0;
        //     document.getElementById('comboTipoCuenta').selectedIndex = 0;
        //     medioPago = "Depósito caja de compensación Los Héroes Tarjeta de prepago"
        //     this.setState({ displayNroCuentaHeroes: 'block', bancoDisplay: 'none', bancoSelected: "", tipoCuenta: "", displayNroCuenta: 'none', nroCuenta: "", nroCuentaHeroesConfirm: "" })
        // }
        this.setState({ medioPago })
    }

    HandlerTipoCuenta = (e) => {
        var tipoCuenta = e.target.value
        var { rut, rutTutor } = this.state


        if (tipoCuenta == 0) {
            this.setState({ tipoCuenta: "", nroCuentaHeroes: "", nroCuenta: "", nroCuentaHeroesConfirm: "", nroCuentaConfirm: "", displayNroCuenta: 'block' })
            return
        } else if (tipoCuenta === 'Cta. Rut') {
            //CUENTA RUT
            if (!rutTutor == "") {
                this.setState({ tipoCuenta, nroCuentaHeroes: "", nroCuentaHeroesConfirm: "", nroCuenta: this.LimpiarFormatoRut(rutTutor.slice(0, -1)), displayNroCuenta: 'block' })
            } else {
                this.setState({ tipoCuenta, nroCuentaHeroes: "", nroCuentaHeroesConfirm: "", nroCuenta: this.LimpiarFormatoRut(rut.slice(0, -1)), displayNroCuenta: 'block' })
            }
            return
        }
        this.setState({ tipoCuenta, nroCuentaHeroes: "", nroCuenta: "", roCuentaHeroesConfirm: "", nroCuentaConfirm: "", displayNroCuenta: 'block' })
    }

    HandlerNroCuenta = (e) => {
        var nroCuenta = e.target.value
        if (isNaN(nroCuenta)) {
            this.setState({ nroCuenta: "", nroCuentaConfirm: "", infoNroCuenta: "El numero de cuenta solo debe incluir valores numericos" })
            return
        }
        this.setState({ nroCuenta, nroCuentaConfirm: "", infoNroCuenta: "" })
    }

    HandlerNroCuentaConfirm = (e) => {
        var cuentaConfirm = e.target.value
        this.setState({ nroCuentaConfirm: cuentaConfirm, infoNroCuentaConfirm: "" })
    }

    HandlerNroCuentaConfirmBlur = (e) => {
        var { nroCuenta, nroCuentaConfirm } = this.state
        if (nroCuenta !== nroCuentaConfirm) {
            this.setState({ nroCuentaConfirm: "", infoNroCuentaConfirm: "El número de cuenta ingresado en la confirmación debe ser igual al original" })
            return
        }
        this.setState({ nroCuentaConfirm: nroCuenta, diclaimerEmpty: "", infoNroCuentaConfirm: "" })
    }

    HandlerNroCuentaHeroes = (e) => {
        var nroCuentaHeroes = e.target.value
        if (isNaN(nroCuentaHeroes)) {
            this.setState({ nroCuentaHeroesConfirm: "", nroCuentaHeroes: "", infoNroCuentaHeroe: "El número de cuenta solo debe incluir valores numericos y tener un largo de 9 digitos" })
            return
        }
        this.setState({ nroCuentaHeroes, nroCuentaHeroesConfirm: "", infoNroCuentaHeroe: "" })
    }


    HandlerNroCuentaHeroesConfirm = (e) => {
        var nroCuentaHeroesConfirm = e.target.value
        this.setState({ nroCuentaHeroesConfirm, infoNroCuentaHeroeconfirm: "" })
    }

    HandlerNroCuentaHeroesConfirmBlur = () => {
        var { nroCuentaHeroesConfirm, nroCuentaHeroes } = this.state;

        if (nroCuentaHeroesConfirm !== nroCuentaHeroes) {
            this.setState({ nroCuentaHeroesConfirm: "", infoNroCuentaHeroeconfirm: "La cuenta de confirmacion no coincide con la original" })
            return
        }
    }

    EmptyValidateBankData = () => {
        var { medioPago, nroCuenta, nroCuentaConfirm, tipoCuenta, bancoSelected, nroCuentaHeroes, nroCuentaHeroesConfirm } = this.state
        if (medioPago == "Cuenta Bancaria") {

            if (bancoSelected == "" || nroCuenta == "" || nroCuentaConfirm == "" || tipoCuenta == "") {
                this.setState({ disclaimerColor: "red", diclaimerEmpty: "Favor revise su información bancaria para continuar con el proceso" })
                return false
            }
        } else if (medioPago == "Depósito caja de compensación Los Héroes Tarjeta de prepago") {
            if (nroCuentaHeroes = "" || nroCuentaHeroesConfirm == "" || nroCuentaHeroes.length < 9) {
                this.setState({ disclaimerColor: "red", diclaimerEmpty: "Favor revise la información de su cuenta, esta debe ser solo numérica y tener un largo de 9 digitos" })
                return false
            }
        } else if (medioPago == "") {
            this.setState({ disclaimerColor: "red", diclaimerEmpty: "Favor vuelva a seleccionar su medio de pago" })
            return false
        }
        this.setState({ diclaimerEmpty: "" })
        return true
    }


    EmptyPension = () => {
        var { deudaPension, displayDeudaPension, deudaPensionConfirm } = this.state

        if (displayDeudaPension === "block") {
            if (deudaPension == "" || deudaPensionConfirm == "") {
                this.setState({ disclaimerColor: "red", diclaimerEmpty: "Favor seleccione una opción de acuerdo a su situación de pensión de alimentos" })
                return false;
            }
        }
        this.setState({ diclaimerEmpty: "" })
        return true;
    }




    EmptyValidateMail = () => {
        var { correo, correoValidacion } = this.state

        if (correo == "" || correoValidacion == "") {
            this.setState({ disclaimerColor: "red", diclaimerEmpty: "Favor verifica la informacón asociada a tu correo electrónico para continuar con la solicitud" })
            return false
        }

        this.setState({ diclaimerEmpty: "" })
        return true
    }


    EmptyValidatePersonalData = () => {
        var { nombre, apellidoPaterno, apellidoMaterno, nroSerie, correo, correoValidacion, celular, fechaNacimiento, direccion, celularConfirm } = this.state

        if (nombre == "" || apellidoPaterno == "" || apellidoMaterno == "" || nroSerie == "" || correo == "" || correoValidacion == "" || celular == "" || celularConfirm == "" || fechaNacimiento == "" || direccion == "") {
            this.setState({ disclaimerColor: "red", diclaimerEmpty: "Favor verifica la tu información personal y de contacto para continuar con la solictud" })
            return false
        } else if (celular.length < 9) {
            this.setState({ disclaimerColor: "red", diclaimerEmpty: "El número de celular debe contener 9 digitos" })
            return false
        }
        this.setState({ diclaimerEmpty: "" })
        return true
    }


    EmptyValidateRetiroData = () => {
        var { ptjeRetiro } = this.state

        if (ptjeRetiro == "") {
            this.setState({ disclaimerColor: "red", diclaimerEmpty: "favor verifique la información del monto/porcentaje de retiro, de ser necesario, vuelva a seleccionarla" })
            return false
        }
        this.setState({ diclaimerEmpty: "" })
        return true
    }


    HandlerRecaptcha = async () => {
        this.setState({ diclaimerEmpty: "", displayLoading: "block", diclaimerValidacion: "Validando los datos ingresados por favor espere...." })
        var token = await this.recaptcha.getResponse();

        var { nombreEjecutivo, backURLSave, nroCuentaHeroes, nombre, apellidoPaterno, apellidoMaterno, rut, nroSerie, correo, correoValidacion, direccion, celular, rutFallecido, ptjeRetiro, medioPago, nroCuenta, tipoCuenta, bancoSelected, fechaNacimiento, clientIP, rutTutor, nroSerieTutor, nombreTutor, apellidoMaternoTutor, apellidoPaternoTutor, deudaPension, esAutoridadPublica } = this.state
        var data = {
            "rut": this.LimpiarRut(rut),
            "nroSerie": nroSerie,
            "nombre": nombre,
            "apellido": apellidoPaterno,
            "apellidoMaterno": apellidoMaterno,
            "rutTutor": this.LimpiarRut(rutTutor),
            "nroSerieTutor": nroSerieTutor,
            "nombreTutor": nombreTutor,
            "apellidoPaternoTutor": apellidoPaternoTutor,
            "apellidoMaternoTutor": apellidoMaternoTutor,
            "email": correo,
            "emailValidacion": correoValidacion,
            "direccion": direccion,
            "telefono": celular,
            "rutFallecido": this.LimpiarRut(rutFallecido),
            "porcentajeRetiro": ptjeRetiro,
            "medioPago": medioPago,
            "banco": bancoSelected,
            "tipoCuenta": tipoCuenta,
            "nroCuenta": nroCuenta ? nroCuenta : nroCuentaHeroes,
            "fechaNacimiento": fechaNacimiento,
            "deudaPension": deudaPension,
            "ip": clientIP,
            "nombreEjecutivo": nombreEjecutivo,
            "EsAutoridadPublica": esAutoridadPublica,
            'captchaToken': token,
            "IND_PRIMER_SEGUNDO_RTO": "201",
            "IND_OTRA_VIA_PAGO_INFO_AFP": "N"
        }

        await fetch(backURLSave, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(res => {
            return res.json();
        })
            .then((response) => {
                if (response.numeroSolicitud !== "") {
                    document.getElementById('submitButton').classList.remove("disabled")
                    this.setState({
                        disableSubmit: false, displayLoading: "none",
                        showModalInformativo: true,
                        textModalInformativo: ReactHtmlParser(`
                    Estimado beneficiario hemos recibido su solicitud exitosamente, a continuación se muestra un resumen de los datos ingresados <br>
                    <ul>
                    <li>Número de solicitud : ${response.numeroSolicitud}</li>
                    <li>Fecha de solicitud : ${response.fechaSolicitud}</li>
                    <li>Vía de pago :   ${response.viaPago}</li>
                    </ul>
                    <br>
                    El paso siguiente es validar su solicitud de la cual se informará su aceptación o rechazo dentro de los siguientes 6 días hábiles.
                    <br>
                    <br>
                    en breve sera redirigido al sitio principal...
                    `),
                        diclaimerValidacion: "", disclaimerColor: "green", diclaimerEmpty: "Hemos recibido su solicitud exitosamente", nombre: "", apellidoPaterno: "", apellidoMaterno: "", rut: "", correo: "", correoValidacion: "", celular: "", rutFallecido: "", ptjeRetiro: "", medioPago: "", nroSerie: "", fechaNacimiento: "", nroCuentaHeroes: "", bancoSelected: "", tipoCuenta: "", celularConfirm: "", nroCuentaConfirm: "", nroCuentaHeroesConfirm: ""
                    })
                    setTimeout(() => {
                        sessionStorage.removeItem('token')
                        sessionStorage.removeItem("rutBeneficiario")
                        sessionStorage.removeItem("rutCausante")
                        window.location = process.env.REACT_APP_backURLReloadBono
                    }, 10000);
                } else if (response.status == 404) {
                    this.setState({ disableSubmit: false, displayLoading: "none", diclaimerValidacion: "", disclaimerColor: "red", diclaimerEmpty: "No es posible continuar. Ya existe una solicitud de retiro en proceso para el RUT ingresado", nombre: "", apellidoPaterno: "", apellidoMaterno: "", rut: "", correo: "", correoValidacion: "", celular: "", rutFallecido: "", ptjeRetiro: "", medioPago: "", nroSerie: "", fechaNacimiento: "", bancoSelected: "", tipoCuenta: "", nroCuentaHeroes: "", celularConfirm: "", nroCuentaConfirm: "", nroCuentaHeroesConfirm: "" })
                } else {
                    this.setState({ disableSubmit: false, displayLoading: "none", diclaimerValidacion: "", disclaimerColor: "red", diclaimerEmpty: "Ha ocurrido un problema al procesar su solicitud, favor intente nuevamente", nombre: "", apellidoPaterno: "", apellidoMaterno: "", rut: "", correo: "", correoValidacion: "", celular: "", rutFallecido: "", ptjeRetiro: "", medioPago: "", nroSerie: "", fechaNacimiento: "", bancoSelected: "", tipoCuenta: "", nroCuentaHeroes: "", celularConfirm: "", nroCuentaConfirm: "", nroCuentaHeroesConfirm: "" })
                }
            }
            )
            .catch(() => {
                document.getElementById('submitButton').classList.remove("disabled")
                this.setState({ disableSubmit: false, displayLoading: "none", diclaimerValidacion: "", disclaimerColor: "red", diclaimerEmpty: "Ha ocurrido un problema al procesar su solicitud, favor intente nuevamente", nick: "", nombre: "", apellidoPaterno: "", apellidoMaterno: "", rut: "", correo: "", correoValidacion: "", celular: "", rutFallecido: "", ptjeRetiro: "", medioPago: "", nroSerie: "", fechaNacimiento: "" })
            })
    }


    HandlerSubmit = async () => {

        if (!this.EmptyValidatePersonalData()) {
            return
        }

        if (!this.EmptyPension()) {
            return;
        }

        if (!this.EmptyValidateMail()) {
            return
        }
        if (!this.EmptyValidateRetiroData()) {
            return;
        }
        if (!this.EmptyValidateBankData()) {
            return;
        }

        if (this.state.terminosConfirm == "") {
            this.setState({ disclaimerColor: "red", diclaimerEmpty: "Favor confirme que esta de acuerdo con los términos y condiciones" })
            return;
        }

        document.getElementById('submitButton').className += " disabled"
        this.setState({ disableSubmit: true })
        this.recaptcha.execute();
    }


    NewHOC = (PassedComponent) => {
        return class extends React.Component {
            render() {
                return (
                    <div>
                        <PassedComponent {...this.props} />
                    </div>
                )
            }
        }
    }


    render() {
        return (<div>
            {this.state.indexRedirect ?
                window.location = process.env.REACT_APP_backURLReloadBono :
                <div class="bg">
                    <ModalInformativo mostrarModal={this.state.showModalInformativo} texto={this.state.textModalInformativo} parentCallBackInformativo={this.ModalInformativoparentCallBackClose}></ModalInformativo>
                    <div className="row register-form" style={{ marginTop: "-2px" }} >

                        <div className="container " >
                            <div class="row">
                                <div class="col-md-3"></div>
                                <div class="col-md-8">
                                    <br></br>
                                    <div class="row "><div class="col-md-12"> <img src={AFPLogo} href="/" onClick={() => this.setState({ indexRedirect: true })}></img></div></div>
                                    <br></br>
                                    <div class="row">
                                        <div class="col-md-12 text-center">
                                            <h1 style={{ fontFamily: "Trebuchet MS,Arial,Helvetica,sans-serif", fontSize: "30px", lineHeight: "100%", color: "#0033A0" }}>Acceso a Solicitud Bono de Cargo Fiscal Beneficiarios de Sobrevivencia</h1>
                          Si eres beneficiario de pensión de sobrevivencia , déjanos tus datos para gestionar tu solicitud.
                          <br></br><br></br><br></br>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-12">
                                            {this.state.ejecutivoLogueado &&
                                                <p>Ingreso Solicitud Ejecutiva/o : {this.state.nombreEjecutivo}</p>
                                            }
                                            <h3 style={{ fontFamily: "Trebuchet MS,Arial,Helvetica,sans-serif", fontSize: "20px", lineHeight: "100%", color: "#00ACCB" }}>
                                                Tus datos personales y de contacto
                      </h3>
                                            <br></br>
                                        </div>
                                    </div>
                                    <div style={{ display: this.state.displayDeudaPension }}>
                                        <div class="row" >
                                            <div class="col-md-6">
                                                Declaro tener una deuda por pensión de alimentos
                          <p className="inputdisclaimertext" style={{ color: 'rgb(0, 51, 160)', fontSize: '12px' }}>{this.state.infoPension}</p>
                                            </div>
                                            <div class="col-md-6">
                                                <div className="form-check">
                                                    <input className="form-check-input" type="radio" name="radiopension" id="gridRadios3" value="1" onClick={(option) => this.HandlerCheckBoxPension(option)}></input>
                                                    <label className="" >
                                                        Si
                              </label>
                                                </div>
                                                <div className="form-check">
                                                    <input className="form-check-input" type="radio" name="radiopension" id="gridRadios4" value="2" onClick={(option) => this.HandlerCheckBoxPension(option)}></input>
                                                    <label className="" >
                                                        No
                              </label>
                                                </div>

                                            </div>
                                        </div>

                                    </div>
                                    <br></br>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <label >Nombre del beneficiario</label>
                                            <input type="email" className="form-control" value={this.state.nombre} onChange={(e) => this.HandlerNombre(e)}></input>
                                            <p className="inputdisclaimertext">{this.state.infoNombre}</p>
                                        </div>

                                    </div>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <label className="">Apellido Paterno del beneficiario</label>
                                            <input type="email" className="form-control" value={this.state.apellidoPaterno} onChange={(e) => this.HandlerApellidoPaterno(e)}></input>
                                            <p className="inputdisclaimertext">{this.state.infoApellido}</p>

                                        </div>
                                        <div class="col-md-6">
                                            <label className="">Apellido Materno del beneficiario</label>
                                            <input type="email" className="form-control" value={this.state.apellidoMaterno} onChange={(e) => this.HandlerApellidoMaterno(e)}></input>
                                            <p className="inputdisclaimertext">{this.state.infoApellido}</p>

                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <label className="" >Correo Electrónico</label>
                                            <input type="email" className="form-control" onCopy={(e) => { e.preventDefault(); return false }} value={this.state.correo} onChange={(e) => this.HandlerEmail(e)} onBlur={(e) => this.HandlerEmailBlur(e)}></input>
                                            <p className="inputdisclaimertext">{this.state.infoMail}</p>
                                        </div>
                                        <div class="col-md-6">
                                            <label className="" >Repite tu Correo Electrónico</label>
                                            <input type="email" className="form-control" onCopy={(e) => { e.preventDefault(); return false }} onPaste={(e) => { e.preventDefault(); return false }} autocomplete="off" value={this.state.correoValidacion} onChange={(e) => this.HandlerEmailValidacion(e)} onBlur={(e) => this.HandlerEmailValidationBlur(e)}></input>
                                            <p className="inputdisclaimertext">{this.state.infoMailValidacion}</p>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-12">
                                            <label className="">Dirección</label>
                                            <input type="email" className="form-control" value={this.state.direccion} onChange={(e) => this.HandlerDireccion(e)} onBlur={(e) => this.HandlerDireccionBlur(e)}></input>
                                            <p className="inputdisclaimertext">{this.state.infoDireccion}</p>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <label className="">Celular</label>
                                            <input type="email" className="form-control" maxLength="9" onCopy={(e) => { e.preventDefault(); return false }} onPaste={(e) => { e.preventDefault(); return false }} value={this.state.celular} onChange={(e) => this.HandlerCelular(e)} onBlur={(e) => this.HandlerCelularBlur(e)}></input>
                                            <p className="inputdisclaimertext">{this.state.infoCelular}</p>
                                        </div>
                                        <div class="col-md-6">
                                            <label className="">Repite tu celular</label>
                                            <input type="email" className="form-control" maxLength="9" onCopy={(e) => { e.preventDefault(); return false }} onPaste={(e) => { e.preventDefault(); return false }} value={this.state.celularConfirm} onChange={(e) => this.HandlerCelularConfirm(e)} onBlur={(e) => this.HandlerCelularBlurConfirm(e)}></input>
                                            <p className="inputdisclaimertext">{this.state.infoCelularConfirm}</p>
                                        </div>
                                    </div>
                                    <br></br>
                                    <div class="row">
                                        <div class="col-md-12">
                                            <br></br>
                                            <h3 style={{ fontFamily: "Trebuchet MS,Arial,Helvetica,sans-serif", fontSize: "20px", lineHeight: "100%", color: "#00ACCB" }}>
                                                Datos para Bono de Cargo Fiscal
                          </h3>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-12">
                                            <Alert variant="warning">
                                                <p>
                                                    Estimado Beneficiario, el monto entregado por la TGR para que pueda acceder al Bono de Cargo Fiscal es de:  ${this.state.saldobono1}
                                                </p>
                                            </Alert>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <label className="" >¿Desea efectuar el monto máximo del retiro (100%) ?</label>
                                            <p className="small-text" style={{ display: this.state.display, color: "#0038a5", }} >Retiro único parcial: Tenga en consideración que si realiza un retiro parcial, no podrá solicitar otro retiro para obtener la diferencia.</p>

                                        </div>
                                        <div class="col-md-6">
                                            <div className="form-check">
                                                <input className="form-check-input" type="radio" name="gridRadios" id="gridRadios1" value="1" onClick={(option) => this.HandlerCombo(option)}></input>
                                                <label className="" >
                                                    SI
                              </label>
                                            </div>
                                            <div className="form-check">
                                                <input className="form-check-input" type="radio" name="gridRadios" id="gridRadios2" value="2" onClick={(option) => this.HandlerCombo(option)}></input>
                                                <label className="" >
                                                    NO
                              </label>
                                                <input id="otroMonto" className="form-control" name="otroMonto" style={{ display: this.state.display }} value={this.state.ptjeRetiro} onChange={(e) => this.HandlerPorcentajeOtro(e)}></input>
                                                {/* <p className="inputdisclaimertext" style={{ color: 'rgb(0, 51, 160)', fontSize: '12px' }} >(*)Si su renta mensual es menor a $1.500.000 millones, no pagará impuestos.</p> */}
                                                <p className="inputdisclaimertext">{this.state.infoPtjeRetiro}</p>

                                            </div>
                                        </div>
                                    </div>
                                    <div class="row" >
                                        <div class="col-md-6">
                                            <label className="" >{this.state.displayTutorData == "block" ? "Medio de Pago del Tutor" : "Medio de Pago"}</label>
                                            <select className="custom-select" id="comboMedioPago" onChange={(e) => this.HandlerMedioPago(e)}>
                                                <option value="0" selected>Seleccionar</option>
                                                <option value="1">Cuenta Bancaria </option>
                                                <option value="2">Cuenta de Ahorro Voluntario (Cuenta 2)</option>
                                                {/* <option value="3">Depósito caja de compensación Los Héroes Tarjeta de prepago</option> */}
                                            </select>
                                        </div>
                                        <div class="col-md-6">

                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-6" style={{ display: this.state.bancoDisplay }}>
                                            <label className=""  >Banco</label>
                                            <select className="custom-select" id="comboBanco" onChange={(e) => this.HandlerBanco(e)}>
                                                <option value="0" selected>Seleccionar</option>
                                                {this.state.bancos.map((element) => {
                                                    return <option id={element} value={element}>{element}</option>
                                                })}
                                            </select>
                                        </div>
                                        <div class="col-md-6" style={{ display: this.state.bancoDisplay }}>
                                            <label className="">Tipo de cuenta</label>
                                            <select className="custom-select" id="comboTipoCuenta" onChange={(e) => this.HandlerTipoCuenta(e)}>
                                                <option value="0" selected>Seleccionar</option>
                                                {this.state.cuentas.map((element) => {
                                                    return <option id={element} value={element}>{element}</option>
                                                })}
                                            </select>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div style={{ display: this.state.displayNroCuenta }}>
                                                <label className="" >{this.state.displayTutorData === "block" ? "N° de cuenta del Tutor" : "N° de cuenta"} </label>
                                                <input type="email" className="form-control" onCopy={(e) => { e.preventDefault(); return false }} onPaste={(e) => { e.preventDefault(); return false }} maxLength={this.state.maxlengthCuenta} value={this.state.nroCuenta} onChange={(e) => this.HandlerNroCuenta(e)}></input>
                                                <p className="inputdisclaimertext" style={{ color: 'rgb(0, 51, 160)', display: this.state.bancoDisplay, fontSize: '12px' }}>
                                                    (*) No está autorizado el depósito en cuenta bipersonales (con más de un titular), ni en cuentas digitales con procesos de apertura no presenciales, evite el rechazo de su solicitud.</p>
                                                <p className="inputdisclaimertext">{this.state.infoNroCuenta}</p>
                                            </div>
                                            <div style={{ display: this.state.displayNroCuentaHeroes }}>
                                                <label  >N° de cuenta caja los Héroes</label>
                                                <input type="email" className="form-control" maxLength="9" onCopy={(e) => { e.preventDefault(); return false }} onPaste={(e) => { e.preventDefault(); return false }} value={this.state.nroCuentaHeroes} onChange={(e) => this.HandlerNroCuentaHeroes(e)}></input>
                                                <p className="inputdisclaimertext">{this.state.infoNroCuentaHeroe}</p>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div style={{ display: this.state.displayNroCuenta }}>
                                                <label className="" >{this.state.displayTutorData === "block" ? "Confirme el N° de cuenta del Tutor" : "Confirme el N° de cuenta"} </label>
                                                <input type="email" className="form-control" onCopy={(e) => { e.preventDefault(); return false }} onPaste={(e) => { e.preventDefault(); return false }} maxLength={this.state.maxlengthCuenta} value={this.state.nroCuentaConfirm} onChange={(e) => this.HandlerNroCuentaConfirm(e)} onBlur={(e) => this.HandlerNroCuentaConfirmBlur(e)}></input>
                                                <p className="inputdisclaimertext">{this.state.infoNroCuentaConfirm}</p>
                                            </div>
                                            <div style={{ display: this.state.displayNroCuentaHeroes }}>
                                                <label >Confirme el N° de cuenta caja los Héroes</label>
                                                <input type="email" className="form-control" maxLength="9" onCopy={(e) => { e.preventDefault(); return false }} onPaste={(e) => { e.preventDefault(); return false }} value={this.state.nroCuentaHeroesConfirm} onChange={(e) => this.HandlerNroCuentaHeroesConfirm(e)} onBlur={(e) => this.HandlerNroCuentaHeroesConfirmBlur(e)}></input>
                                                <p className="inputdisclaimertext">{this.state.infoNroCuentaHeroeconfirm}</p>

                                            </div>
                                        </div>
                                    </div>

                                    <br></br>
                                    <div style={{ display: this.state.displayDeudaPension }}>
                                        <div class="row" >
                                            <div class="col-md-6">
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" name="x" id="xt" onClick={() => { this.setState({ deudaPensionConfirm: true }) }} ></input>
                                                    <label className="" >
                                                        {`${this.state.deudaPension}, ${this.state.deudaPension} tengo deuda de pensión de alimentos`}
                                                    </label>
                                                </div>
                                            </div>
                                            <div class="col-md-6">

                                            </div>
                                        </div>

                                    </div>
                                    <br></br>
                                    <div style={{ display: 'block' }}>
                                        <div class="row" >
                                            <div class="col-md-6">
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" name="ui" id="er" value="2" onClick={() => this.setState({ terminosConfirm: true })}></input>
                                                    <label className="" >
                                                        He leido y acepto los <a href="#" onClick={(e) => {
                                                            e.preventDefault()
                                                            this.setState({
                                                                showModalInformativo: true, textModalInformativo: ReactHtmlParser(`
                                                
                                                <strong>Impacto pensión</strong> <br>
-	Conoce y acepta que el monto del retiro tendrá un impacto negativo en el monto de su pensión. <br>
<br>
<strong>Inembargabilidad de los fondos</strong>  <br>
-	Los fondos retirados se considerarán extraordinariamente intangibles para todo efecto legal, y no serán objeto de retención, descuento, compensación legal o contractual, embargo o cualquier forma de afectación judicial o administrativa, ni podrá rebajarse del monto ya decretado de la compensación económica en el juicio de divorcio, sin perjuicio de las deudas originadas por obligaciones alimentarias.  <br>
<br>
<strong>Pensionados</strong><br>
-	Si Ud. es un pensionado bajo la modalidad de retiro programado, su pensión se recalculará considerando el menor saldo de la cuenta individual en el mes en que corresponde su anualidad. Si efectúa el retiro por la totalidad del saldo de su cuenta individual de cotizaciones obligatorias podría quedarse sin pensión, de no tener acceso a beneficios del pilar solidario. En el caso de pensiones de vejez, se puede acceder a los beneficios del pilar solidario a partir de los 65 años de edad, cumpliendo los requisitos respectivos.<br>
-	Si Ud. es un pensionado y se encuentra en el periodo de renta temporal, el retiro se considerará en el próximo cálculo de anualidad y si no corresponde un nuevo recálculo, dependiendo del monto del retiro, podría implicar que se quede algunos meses sin pensión, previo al inicio del pago de su renta vitalicia.<br>
<br>
<strong>Cambio de fondos</strong><br>
-	Se puede ingresar una solicitud de retiro desde la cuenta obligatoria, teniendo un cambio o distribución de fondos en curso, siempre y cuando la fecha de materialización del último, sea a más tardar la fecha en que se haya aprobado la solicitud de retiro.<br>
-	En el caso que el cambio o distribución de fondos se materialice en plazos que exceden la fecha de aprobación del retiro de fondos, el cambio o distribución se hará efectivo luego de la fecha del pago del retiro. Es decir, una vez que haya quedado disponible el pago total del retiro de fondos, se podrá aceptar nuevamente la tramitación de cambio o distribución de fondos.<br>
<br>
<strong>Cambio de AFP</strong><br>
-	Si a la fecha en que se valide la solicitud de retiro, la cuenta individual no se encuentra bloqueada producto del proceso de traspaso de AFP, la solicitud de retiro se procesará en la AFP de origen. En este caso, el proceso de traspaso se pospone hasta que concluya el pago del retiro. Lo anterior, deberá ser comunicado oportunamente al afiliado.<br>
-	Si a la fecha en que se valide la solicitud de retiro, la cuenta individual se encuentra cerrada o cuando ya se efectuó el cargo con motivo de un traspaso de AFP, la solicitud de retiro será rechazada, y se le indicará al afiliado que producto de su solicitud de traspaso, debe presentar nuevamente su solicitud en la AFP de destino, una vez que esté abierta la cuenta individual en esta AFP.<br>
<br>
<strong>Pensión de alimentos</strong><br>
-	Si selecciona tener una deuda de pensión de alimentos, el monto solicitado será retenido hasta confirmar la existencia de la medida cautelar. <br>
-	Si el afiliado o beneficiario declara por error la existencia de una deuda alimentaria, sin haberse iniciado una demanda de alimentos en su contra ante los Tribunales de Justicia, no es posible probar el hecho negativo de la inexistencia de la deuda, por falta de Tribunal competente para emitir tal declaración. En este caso, deberá ingresar nuevamente una solicitud, declarando que no es deudor de pensión de alimentos.<br> 
<br>
<strong>Pensionados por enfermedad terminal </strong><br>
-	Si Ud. es se encuentra pensionado por enfermedad terminal y se encuentra en el periodo de renta temporal, una vez materializado el retiro la Administradora deberá recalcular la renta temporal y la reserva por concepto de pensiones de sobrevivencia y cuota mortuoria  considerando el menor saldo de la cuenta individual. Si efectúa el retiro por la totalidad del saldo de su cuenta individual de cotizaciones obligatorias podría quedarse sin pensión.
.<br>
`)
                                                            });
                                                        }}>terminos y condiciones </a>
                                                    </label>
                                                </div>
                                            </div>
                                            <div class="col-md-6">

                                            </div>
                                        </div>

                                    </div>
                                    <br></br>

                                    <br></br>
                                    <br></br>
                                    <div class="text-center">

                                        <div class="row">
                                            <div class="col-md-4">
                                            </div>
                                            <div class="col-md-4">
                                                <button className="btnRegister montoRetiroNumero" id="submitButton" disabled={this.state.disableSubmit} onClick={() => this.HandlerSubmit()}>Enviar</button>
                                                <Recaptcha
                                                    ref={ref => this.recaptcha = ref}
                                                    sitekey={this.state.sitekey}
                                                    onResolved={() => this.HandlerRecaptcha()} />
                                            </div>
                                            <div class="col-md-4">
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-12">
                                                <p className="small-text" id="disclaimerInfo" style={{ 'fontSize': '1rem', color: 'rgb(0, 51, 160)', fontWeight: "bold" }}>{this.state.diclaimerValidacion}</p>
                                                <p className="small-text" id="disclaimerInfo" style={{ 'fontSize': '1rem', color: 'green', fontWeight: "bold" }}>{this.state.diclaimerEmpty}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-1"></div>

                            </div>
                        </div>
                    </div>
                    <Footer></Footer>
                </div>
            }
        </div>
        )

    }
}

export default SolicitudBono;