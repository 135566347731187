import React from "react";
import { Card } from 'react-bootstrap';
import { Link } from "react-router-dom";
import AFPLogo from '../../logo_afp.png';
import Footer from "../../componentes/footer";

class Home extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            data_retiro: []
        }
    }

    componentDidMount() {
        if (this.props) {
            //   console.log("propiedades ",this.props.data_retiro )
            this.setState({ data_retiro: this.props.data_retiro })
            this.props.data_retiro.sort((a, b) => a.numero_retiro - b.numero_retiro).map((retiro) => {
                console.log("Retiro: " + retiro.numero_retiro)
                console.log("Firestore: " + retiro.coleccionSolicitudesBeneficiario)
            })
        }
    }

    render() {
        return <div class="bg">
            <div class="row">
                <div class="col-md-1"></div>
                <div class="col-md-10">
                    <div class="text-center"><br></br>
                        <h1 class="display-4"><img src={AFPLogo} href="/"></img></h1>

                        <p class="lead">Portal para el retiro de fondos de sobrevivencia del 10%</p>
                    </div>
                    <br></br>




                    <div class="row">
                        <div class="col-md-2"></div>
                        <div class="col-md-10 text-center">
                            <div class="container">
                                <div class="card-deck mb-12 text-center">

                                    {this.state.data_retiro.sort((a, b) => a.numero_retiro - b.numero_retiro).map((retiro) => {
                                        return <Card class="rounded" style={{ width: '18rem' }} >
                                            {retiro.puede_hacer_retiro ? <Card.Header style={{ opacity: '1', color: "white", fontSize: "20px", fontWeight: "bold", backgroundColor: "#0033A0" }}>{`${retiro.numero_retiro} retiro de fondos de sobrevivencia`} </Card.Header>
                                                : <Card.Header style={{ opacity: '1', color: "grey", fontSize: "20px", fontWeight: "bold", backgroundColor: "#00000" }}>{`${retiro.numero_retiro} retiro de fondos de sobrevivencia`} </Card.Header>
                                            }

                                            <Card.Body>
                                                <Card.Title><div style={{ opacity: '1' }} class="circulo2"><h2>{retiro.numero_retiro}</h2></div></Card.Title>
                                                <Card.Text style={{ opacity: '1', fontSize: "16px" }}>
                                                    <p style={{ textAlign: "justify" }}>Puedes retirar un mín. de UF35 en una cuota y máx. de UF150 hasta en 2 cuotas (50% en 10 días hábiles y el otro 50% a 10 días hábiles luego del primer pago).</p>
                                                </Card.Text>
                                            </Card.Body>
                                            <footer style={{ backgroundColor: '#FFFFFF', marginLeft: '10px', marginRight: '10px' }}>
                                                {retiro.puede_hacer_retiro ? <Link to={{ pathname: '/retiro10', query: { numero_retiro: retiro.numero_retiro, coleccionRetiro: retiro.coleccionSolicitudesBeneficiario } }}><button type="button" class="btnRegister montoRetiroNumero">Entrar</button></Link>
                                                    : "Solicitud Ingresada"
                                                }
                                            </footer>
                                        </Card>
                                    })}
                                </div>


                            </div>
                            <br></br>

                        </div>
                        <div class="col-md-2">

                        </div>
                    </div>


                </div>

                <div class="col-md-1">

                </div>


            </div>

            <div>

            </div>


            <Footer></Footer>

        </div>
    }
}

export default Home;